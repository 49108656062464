import React, { useState, useRef, useEffect } from "react";
import { X } from "lucide-react";
import { Button } from "simp-ui-elements";

const Select = ({ value, onValueChange, children, placeholder }) => {
  return (
    <select
      value={value}
      onChange={(e) => onValueChange(e.target.value)}
      className="select"
    >
      <option value="">{placeholder}</option>
      {children}
    </select>
  );
};
const Slider = ({ min, max, step, value, onValueChange }) => {
  return (
    <input
      type="range"
      min={min}
      max={max}
      step={step}
      value={value}
      className="slider"
      onChange={(e) => onValueChange([parseInt(e.target.value)])}
    />
  );
};
const Input = ({ type, value, onChange, className }) => {
  return (
    <input
      type={type}
      value={value}
      onChange={onChange}
      className={className}
    />
  );
};

const FilterSystem = () => {
  const [activeFilter, setActiveFilter] = useState(null);
  const [priceRange, setPriceRange] = useState([0, 250]);
  const [yearRange, setYearRange] = useState([1990, 2024]);
  const [brand, setBrand] = useState("");
  const [model, setModel] = useState("");
  const filterRefs = useRef({});
  const [filterPosition, setFilterPosition] = useState({ top: 0, left: 0 });

  const handleFilterClick = (filter) => {
    if (activeFilter === filter) {
      setActiveFilter(null);
    } else {
      setActiveFilter(filter);
      const rect = filterRefs.current[filter].getBoundingClientRect();
      setFilterPosition({
        top: rect.top,
        left: rect.left + window.scrollX,
      });
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        activeFilter &&
        !filterRefs.current[activeFilter].contains(event.target)
      ) {
        setActiveFilter(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [activeFilter]);

  const handlePriceChange = (newValues) => {
    setPriceRange(newValues);
  };

  const handleYearChange = (newValues) => {
    setYearRange(newValues);
  };

  const handleInputChange = (index, value, setter, min, max) => {
    const parsedValue = parseInt(value);
    if (!isNaN(parsedValue)) {
      const newValues = [...(index === 0 ? setter : setter)];
      newValues[index] = Math.max(min, Math.min(parsedValue, max));
      if (index === 0 && newValues[0] > newValues[1]) {
        newValues[1] = newValues[0];
      } else if (index === 1 && newValues[1] < newValues[0]) {
        newValues[0] = newValues[1];
      }
      setter(newValues);
    }
  };

  const renderFilterContent = () => {
    switch (activeFilter) {
      case "price":
        return (
          <div className="p-4">
            <Slider
              min={0}
              max={100000}
              step={1000}
              value={priceRange}
              onValueChange={handlePriceChange}
            />
            <div className="mt-2 flex justify-between">
              <Input
                type="number"
                value={priceRange[0]}
                onChange={(e) =>
                  handleInputChange(0, e.target.value, setPriceRange, 0, 100000)
                }
                className="w-24"
              />
              <Input
                type="number"
                value={priceRange[1]}
                onChange={(e) =>
                  handleInputChange(1, e.target.value, setPriceRange, 0, 100000)
                }
                className="w-24"
              />
            </div>
          </div>
        );
      case "year":
        return (
          <div className="p-4">
            <Slider
              min={1990}
              max={2024}
              step={1}
              value={yearRange}
              onValueChange={handleYearChange}
            />
            <div className="mt-2 flex justify-between">
              <Input
                type="number"
                value={yearRange[0]}
                onChange={(e) =>
                  handleInputChange(0, e.target.value, setYearRange, 1990, 2024)
                }
                className="w-24"
              />
              <Input
                type="number"
                value={yearRange[1]}
                onChange={(e) =>
                  handleInputChange(1, e.target.value, setYearRange, 1990, 2024)
                }
                className="w-24"
              />
            </div>
          </div>
        );
      case "brand":
        return (
          <div className="p-4">
            <Select
              value={brand}
              onValueChange={setBrand}
              placeholder="Select a brand"
            >
              <Select.Option value="toyota">Toyota</Select.Option>
              <Select.Option value="honda">Honda</Select.Option>
              <Select.Option value="ford">Ford</Select.Option>
              {/* Add more brand options */}
            </Select>
          </div>
        );
      case "model":
        return (
          <div className="p-4">
            <Select
              value={model}
              onValueChange={setModel}
              placeholder="Select a model"
            >
              <Select.Option value="camry">Camry</Select.Option>
              <Select.Option value="civic">Civic</Select.Option>
              <Select.Option value="f150">F-150</Select.Option>
              {/* Add more model options */}
            </Select>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="relative">
      <div className="flex space-x-2 mb-4">
        {["price", "year", "brand", "model"].map((filter) => (
          <button
            key={filter}
            ref={(el) => (filterRefs.current[filter] = el)}
            onClick={() => handleFilterClick(filter)}
            className={`px-4 py-2 rounded transition-colors duration-200 ${
              activeFilter === filter
                ? "bg-black text-white z-50"
                : "bg-gray-200 text-black hover:bg-gray-300"
            }`}
          >
            {filter.charAt(0).toUpperCase() + filter.slice(1)}
          </button>
        ))}
      </div>
      {activeFilter && (
        <>
          <div className="fixed inset-0 bg-black bg-opacity-50 z-40" />
          <div
            className="absolute bg-white rounded-lg shadow-lg z-50"
            style={{
              top: `${filterPosition.top}px`,
              left: `${filterPosition.left}px`,
            }}
          >
            <div className="p-4 w-96 relative">
              <button
                onClick={() => setActiveFilter(null)}
                className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
              >
                <X size={24} />
              </button>
              <h2 className="text-xl font-bold mb-4">
                {activeFilter.charAt(0).toUpperCase() + activeFilter.slice(1)}{" "}
                Filter
              </h2>
              {renderFilterContent()}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

function TestingView() {
  return (
    <>
      <FilterSystem />
      <Button />
    </>
  );
}

export default TestingView;
