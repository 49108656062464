import { Link } from "react-router-dom";
function DesktopSearch(props) {
  return (
    <>
      <rectanglecard style={{ backgroundColor: "white" }}>
        <row className="jcb aic">
          <column style={{ width: "100%" }}>
            <h6>Where</h6>
            <input
              type="text"
              name
              id
              className="inv"
              placeholder="City, airport, address or hotel"
            />
          </column>
          <row className="jcb aic">
            <column>
              <h6>From</h6>
              <input type="date" name id className="inv" />
            </column>
            <img
              src="svgs/rightArrow.svg"
              alt
              style={{ width: 24, height: 24, margin: "0 8px" }}
            />
            <column>
              <h6>Until</h6>
              <input type="date" name id className="inv" />
            </column>
          </row>
        </row>
        <Link
          to="/listings"
          style={{
            height: "100%",
          }}
        >
          <column
            className="jcc aic pc"
            style={{
              backgroundColor: "#33d646",
              height: "100%",
              width: 100,
              borderRadius: "0 50px 50px 0px",
            }}
          >
            <svg
              width={23}
              height={24}
              viewBox="0 0 23 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.6 10.1671C0.6 15.0497 4.53439 18.9841 9.41707 18.9841C14.2997 18.9841 18.2341 15.0497 18.2341 10.1671C18.2341 5.28439 14.2997 1.35 9.41707 1.35C4.53439 1.35 0.6 5.28439 0.6 10.1671ZM2.69493 10.1671C2.69493 6.43844 5.68844 3.44493 9.41707 3.44493C13.1457 3.44493 16.1392 6.43844 16.1392 10.1671C16.1392 13.8957 13.1457 16.8892 9.41707 16.8892C5.68844 16.8892 2.69493 13.8957 2.69493 10.1671Z"
                fill="white"
                stroke="white"
                strokeWidth="0.8"
              />
              <path
                d="M15.9686 15.2367L15.6858 14.9538L15.4029 15.2367L14.4874 16.1522L14.2046 16.435L14.4874 16.7179L20.3017 22.5321L20.5845 22.815L20.8674 22.5321L21.7829 21.6166L22.0657 21.3338L21.7829 21.0509L15.9686 15.2367Z"
                fill="white"
                stroke="white"
                strokeWidth="0.8"
              />
            </svg>
          </column>
        </Link>
      </rectanglecard>
    </>
  );
}

export default DesktopSearch;
