import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const validImageTypes = ['image/jpeg', 'image/png', 'image/gif'];

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
};

const Picture = ({ file, index }) => (
    <Draggable key={index} draggableId={`item-${index}`} index={index}>
        {(provided) => (
            <div
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                className="image-preview"
                style={{ ...provided.draggableProps.style }}
            >
                <img src={URL.createObjectURL(file)} alt={`preview ${index}`} />
            </div>
        )}
    </Draggable>
);

const PicturesComponent = ({ images, setImages, onError }) => {
    const onDrop = (acceptedFiles) => {
        const validFiles = acceptedFiles.filter(file => 
            validImageTypes.includes(file.type)
        );

        if (validFiles.length + images.length > 10) {
            onError('You can only upload up to 10 images.');
        } else if (validFiles.length < acceptedFiles.length) {
            onError('Some files were not valid images and were not uploaded.');
        } else {
            onError('');
        }

        setImages(prevImages => [...prevImages, ...validFiles.slice(0, 10 - prevImages.length)]);
    };

    const { getRootProps, getInputProps } = useDropzone({
        accept: validImageTypes.join(','),
        onDrop
    });

    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }

        const newImages = reorder(
            images,
            result.source.index,
            result.destination.index
        );

        setImages(newImages);
    };

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <div>
                <div {...getRootProps()} className="dropzone">
                    <input {...getInputProps()} />
                    <p>Drag & drop some files here, or click to select files</p>
                </div>
                <Droppable droppableId="droppable">
                    {(provided) => (
                        <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            className="image-previews d-flex"
                        >
                            {images.map((file, index) => (
                                <Picture key={index} file={file} index={index} />
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </div>
        </DragDropContext>
    );
};

export default PicturesComponent;
