import React from "react";
import styled from "styled-components";
import CarCard from "./CarCard";
import { Link } from "react-router-dom";

const carData = [
  {
    image:
      "https://cdn.builder.io/api/v1/image/assets/TEMP/8e379d0323e3c48cc30fdd2c59574a1d89749dbe40333ff759db73520f501225?apiKey=5cb2fb1eca5947a5aceaec37f3e2113b&&apiKey=5cb2fb1eca5947a5aceaec37f3e2113b",
    price: "US$ 35/día",
    title: "Honda Civic 2019",
    rating: "90",
  },
  {
    image:
      "https://cdn.builder.io/api/v1/image/assets/TEMP/8e379d0323e3c48cc30fdd2c59574a1d89749dbe40333ff759db73520f501225?apiKey=5cb2fb1eca5947a5aceaec37f3e2113b&&apiKey=5cb2fb1eca5947a5aceaec37f3e2113b",
    price: "US$ 35/día",
    title: "Honda Civic 2019",
    rating: "90",
  },
  {
    image:
      "https://cdn.builder.io/api/v1/image/assets/TEMP/8e379d0323e3c48cc30fdd2c59574a1d89749dbe40333ff759db73520f501225?apiKey=5cb2fb1eca5947a5aceaec37f3e2113b&&apiKey=5cb2fb1eca5947a5aceaec37f3e2113b",
    price: "US$ 35/día",
    title: "Honda Civic 2019",
    rating: "90",
  },
];

const CarListingSection = () => {
  return (
    <SectionWrapper>
      <ListingContainer>
        {carData.map((car, index) => (
          <ListingColumn key={index}>
            <Link to="/listing/0" className="custom-link">
              <CarCard {...car} />
            </Link>
          </ListingColumn>
        ))}
      </ListingContainer>
    </SectionWrapper>
  );
};

const SectionWrapper = styled.section`
  justify-content: space-between;
  align-self: stretch;
  max-width: 900px;
  padding: 0 60px;

  @media (max-width: 991px) {
    padding: 0 20px;
  }
`;

const ListingContainer = styled.div`
  display: flex;
  gap: 20px;

  @media (max-width: 991px) {
    flex-direction: column;
    align-items: stretch;
    gap: 0;
  }
`;

const ListingColumn = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 33%;
  margin-left: 0;

  @media (max-width: 991px) {
    width: 100%;
    margin-top: 40px;
  }
`;

export default CarListingSection;
