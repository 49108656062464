import { useSelector, useDispatch } from "react-redux";
import React, { useState } from "react";
import { Sparkles } from "lucide-react";
import TripCard from "./TripCard";

function MyTrips() {
  return (
    <div className="p-4 max-w-3xl mx-auto">
      <h1 className="text-2xl font-bold mb-4">Trips</h1>

      <div className="bg-white rounded-lg shadow-md overflow-hidden mb-6">
        <div className="p-6 flex items-start">
          <div className="flex-grow">
            <div className="flex items-center mb-2">
              <Sparkles className="w-6 h-6 text-green-500 mr-2" />
              <h2 className="text-lg font-semibold">No trips booked... yet!</h2>
            </div>
            <p className="text-gray-600 mb-4">
              Time to dust off your bags and start planning your next adventure
            </p>
            <button className="bg-gray-200 text-gray-700 px-4 py-2 rounded-md hover:bg-gray-300 transition-colors">
              Start searching
            </button>
          </div>
          <div className="w-1/2 bg-gray-100 h-40"></div>
        </div>
      </div>

      <h2 className="text-xl font-semibold mb-4">What have you rented?</h2>
      <TripCard />
    </div>
  );
}

export default MyTrips;
