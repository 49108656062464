// src/components/NavBar.jsx

import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { MessageSquare, HelpCircle } from "lucide-react";
import axios from "axios";
import { loginSuccess, logout, pushListings } from "../stores/stores"; // Correct import for login and logout actions

import backendUrl from "./functions/backendUrl";

const NavBar = ({ onShowRegister, onShowLogin }) => {
  let baseUrl = backendUrl();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const loggedIn = useSelector((state) => state.auth.loggedIn);
  console.log(user, loggedIn);
  let listings;
  const handleLogout = () => {
    dispatch(logout());
    window.localStorage.removeItem("token");
    window.localStorage.removeItem("userId");
  };

  useEffect(() => {
    const token = window.localStorage.getItem("token");
    const userId = window.localStorage.getItem("userId");
    const fetchData = async () => {
      // fetch data
      if (token) {
        axios
          .post(baseUrl + "token-reverse/", { token, userId })
          .then((response) => {
            const { user, token } = response.data;
            dispatch(loginSuccess(user, token));
          })
          .catch((error) => {
            console.error("Error during token reverse:", error);
            window.localStorage.removeItem("token");
          });
      }
      axios
        .get(baseUrl + "api/listings")
        .then((response) => {
          listings = response.data;
          dispatch(pushListings(listings));
        })
        .catch((error) => {
          console.error("Error during token reverse:", error);
          window.localStorage.removeItem("token");
        });
    };
    fetchData();
  }, [dispatch]);
  return (
    <>
      <navbar className="container-fluid navbar jcb aic">
        <Link to="/">
          <h3 className="pc">Wheely</h3>
        </Link>
        {loggedIn ? (
          <profiletab className="relative">
            <row className="jcc aic" style={{ gap: 8 }}>
              <svg
                className="seo-pages-1xdhyk6"
                data-testid="IconHamburgerMenu24"
                fill="none"
                height={24}
                viewBox="0 0 24 24"
                width={24}
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M19.15 6.85H4.85a.76.76 0 0 1-.75-.75c0-.41.34-.75.75-.75h14.3c.41 0 .75.34.75.75s-.34.75-.75.75m0 5.76H4.85a.76.76 0 0 1-.75-.75c0-.41.34-.75.75-.75h14.3c.41 0 .75.34.75.75s-.34.75-.75.75m0 6.01H4.85a.76.76 0 0 1-.75-.75c0-.41.34-.75.75-.75h14.3c.41 0 .75.34.75.75s-.34.75-.75.75"
                  fill="#121214"
                />
              </svg>
              <img
                src={
                  user.profilePicture
                    ? user.profilePicture.url
                    : "img/profile.jpeg"
                }
                alt="profile"
                style={{ width: 22, height: 22, borderRadius: "50%" }}
              />
            </row>
            <div
              className="absolute"
              style={{
                backgroundColor: "rgba(0, 0, 0, 0)",
                height: 18,
                width: 65,
                top: 30,
              }}
            />
            <hover className="absolute" style={{ top: 46, right: 0 }}>
              <Link to="/testingview">
                <iconrow>
                  <HelpCircle />
                  Testing View
                </iconrow>
              </Link>
              <Link to="/mylistings">
                <iconrow>
                  <svg
                    className="seo-pages-1xdhyk6"
                    data-testid="IconDashboard24"
                    fill="none"
                    height={24}
                    viewBox="0 0 24 24"
                    width={24}
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M16.71 4.53c.42 0 .88.06 1.34.18.04 0 3.58 1.15 2.37 6.01-.84 3.36-5.76 7.62-8.18 9.44a.39.39 0 0 1-.46-.01l-1.8-1.42c-3.6-3.04-5.88-5.89-6.41-8.01-1.21-4.85 2.33-6 2.37-6.01.46-.12.92-.18 1.34-.18 1.99 0 3.15"
                      fill="#121214"
                    />
                  </svg>
                  My Listings
                </iconrow>
              </Link>
              <Link to="/inbox">
                <iconrow className="mt-1">
                  <svg
                    className="css-1xdhyk6"
                    data-testid="IconChatBubble24"
                    fill="none"
                    height={24}
                    viewBox="0 0 24 24"
                    width={24}
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.5 21.63c-.1 0-.2-.02-.3-.07a.64.64 0 0 1-.32-.56v-3.81c0-.34.28-.62.62-.62s.62.28.62.62v2.65l4.86-3.2c.1-.07.22-.1.34-.1h4.63c.43 0 .84-.17 1.14-.47.31-.31.47-.71.47-1.15V4.86c0-.89-.73-1.61-1.62-1.61H4.87c-.43 0-.84.17-1.15.48s-.47.71-.47 1.14l.02 10.05c0 .89.73 1.61 1.62 1.61.34 0 .62.28.62.62s-.28.62-.62.62c-1.58 0-2.86-1.28-2.87-2.86L2 4.87c0-.77.3-1.49.84-2.03S4.1 2 4.87 2h14.07c1.58 0 2.86 1.28 2.87 2.86l.02 10.05c0 .77-.3 1.49-.84 2.03s-1.26.84-2.03.84h-4.44l-5.67 3.74c-.11.07-.23.11-.35.11"
                      fill="#121214"
                    />
                  </svg>
                  Inbox
                </iconrow>
              </Link>
              <Link to="/trips">
                <iconrow className="mt-1">
                  <svg
                    className="css-1xdhyk6"
                    data-testid="IconRoad124"
                    fill="none"
                    height={24}
                    viewBox="0 0 24 24"
                    width={24}
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12.1 21.7c-.34 0-.62-.28-.62-.62v-1.89c0-.34.28-.62.62-.62s.62.28.62.62v1.89c0 .34-.28.62-.62.62M12.1 16.51c-.34 0-.62-.28-.62-.62v-2.43c0-.34.28-.62.62-.62s.62.28.62.62v2.43c0 .34-.28.62-.62.62M12.1 11.09c-.34 0-.62-.28-.62-.62V8.04c0-.34.28-.63.62-.63s.62.28.62.63v2.42c0 .35-.28.63-.62.63M12.1 5.37c-.34 0-.62-.28-.62-.62V2.93c0-.34.28-.62.62-.62s.62.28.62.62v1.82c0 .34-.28.62-.62.62M21.97 21.62c-.26 0-.51-.17-.59-.43L15.44 3.31c-.11-.33.07-.68.4-.79s.68.07.79.4l5.94 17.88a.624.624 0 0 1-.6.82M2.23 21.62c-.07 0-.13-.01-.2-.03a.63.63 0 0 1-.4-.79L7.57 2.92c.11-.33.46-.51.79-.4s.5.46.4.79L2.82 21.2c-.09.26-.33.42-.59.42"
                      fill="#121214"
                    />
                  </svg>
                  Trips
                </iconrow>
              </Link>
              <Link to="/account">
                <iconrow className="mt-1">
                  <svg
                    className="css-1xdhyk6"
                    data-testid="IconAvatar124"
                    fill="none"
                    height={24}
                    viewBox="0 0 24 24"
                    width={24}
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M21.13 21.73H2.62c-.34 0-.62-.28-.62-.62 0-2.26 1.03-4.34 1.08-4.43.94-1.69 2.41-2.82 3.49-3.48.29-.18.68-.09.86.21.18.29.09.68-.21.86-.95.58-2.24 1.57-3.03 3 0 0-.74 1.51-.9 3.22h17.2c-.15-1.91-.95-3.17-.96-3.19-2.63-4.37-7.51-4.42-7.59-4.42-3 0-5.44-2.44-5.44-5.44S8.93 2 11.93 2s5.44 2.44 5.44 5.44c0 1.08-.32 2.13-.92 3.02a.626.626 0 1 1-1.04-.7c.46-.69.7-1.49.7-2.33 0-2.31-1.88-4.19-4.19-4.19s-4.18 1.89-4.18 4.2 1.88 4.19 4.19 4.19c.2 0 5.66.03 8.65 5 .04.05 1.18 1.83 1.18 4.47 0 .35-.28.63-.63.63"
                      fill="#121214"
                    />
                  </svg>
                  Account
                </iconrow>
              </Link>
              <Link to="/profile">
                <iconrow className="mt-1">
                  <img
                    style={{ height: 24, borderRadius: "50%" }}
                    alt="profile-image"
                    src={
                      user.profilePicture
                        ? user.profilePicture.url
                        : "img/profile.jpeg"
                    }
                    data-testid="profilePhoto-image"
                    className="css-1fozzl1-StyledImage"
                  />
                  Profile
                </iconrow>
              </Link>
              <Link to="/list">
                <iconrow className="mt-1">
                  <svg
                    className="css-1xdhyk6"
                    data-testid="IconCar24"
                    fill="none"
                    height={24}
                    viewBox="0 0 24 24"
                    width={24}
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M18.86 7.3c.1.24.33.38.57.38q.135 0 .24-.06c.32-.14.47-.5.33-.82l-1.42-3.29a2.29 2.29 0 0 0-2.1-1.38H7.51c-.92 0-1.74.54-2.1 1.38L3.99 6.8c-.13.32.02.68.33.82s.68 0 .82-.32l1.42-3.29c.16-.37.54-.62.95-.62h8.98c.41 0 .79.24.95.62zM19.49 13.92a1.6 1.6 0 1 1-3.2 0 1.6 1.6 0 0 1 3.2 0M6.11 15.52a1.6 1.6 0 1 0 0-3.2 1.6 1.6 0 0 0 0 3.2"
                      fill="#121214"
                    />
                    <path
                      clipRule="evenodd"
                      d="M15.43 18.69H8.55v2.4c.02.69-.54 1.25-1.23 1.25H4.91c-.69 0-1.25-.56-1.25-1.25v-2.413a2.545 2.545 0 0 1-2.28-2.527V11.7c0-1.4 1.14-2.54 2.54-2.54h16.17c1.4 0 2.54 1.14 2.54 2.54v4.45a2.55 2.55 0 0 1-2.3 2.528v2.412c.02.69-.54 1.25-1.24 1.25h-2.41c-.69 0-1.25-.56-1.25-1.25zm3.66 0h-2.42v2.4h2.42zm-14.19 0h2.41v2.4H4.9zm-2.27-7c0-.71.58-1.29 1.29-1.29v.01h16.17c.71 0 1.29.58 1.29 1.29v4.44c0 .71-.58 1.29-1.29 1.29H3.92c-.71 0-1.29-.58-1.29-1.29z"
                      fill="#121214"
                      fillRule="evenodd"
                    />
                  </svg>
                  Publica tu carro
                </iconrow>
              </Link>
              <iconrow
                className="mt-1"
                onClick={handleLogout}
                style={{ cursor: "pointer" }}
              >
                <svg
                  className="css-1xdhyk6"
                  data-testid="IconLogout24"
                  fill="none"
                  height={24}
                  viewBox="0 0 24 24"
                  width={24}
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.35 21.5H4.63a.63.63 0 0 1-.63-.62V3.62A.63.63 0 0 1 4.63 3h7.72a.62.62 0 0 1 0 1.24H5.22v16h7.1a.63.63 0 1 1 0 1.25z"
                    fill="#121214"
                  />
                  <path
                    d="M20.84 12.25v-.07a.63.63 0 0 0-.17-.37l-4-4a.62.62 0 0 0-.88.88l2.93 2.93h-9a.63.63 0 1 0 0 1.25h9l-2.91 2.91a.64.64 0 0 0 0 .89.63.63 0 0 0 .88 0l4-4a.8.8 0 0 0 .17-.24.6.6 0 0 0 0-.19z"
                    fill="#121214"
                  />
                </svg>
                Logout
              </iconrow>
            </hover>
          </profiletab>
        ) : (
          <div className=" wrap" style={{ columnGap: "14px" }}>
            <p
              className="pc"
              style={{ marginBottom: "0px", lineHeight: "16px" }}
              onClick={onShowLogin}
            >
              Ingresar
            </p>
            <p
              className="pc"
              style={{ marginBottom: "0px", lineHeight: "16px" }}
              onClick={onShowRegister}
            >
              Regístrate
            </p>
          </div>
        )}
      </navbar>
    </>
  );
};

export default NavBar;
