import React, { useState } from "react";
import { Search, ChevronDown, Heart, MapPin, Settings } from "lucide-react";
import { useSelector } from "react-redux";

import { Link } from "react-router-dom";
const ListingsSearch = () => {
  const [isPriceFilterOpen, setIsPriceFilterOpen] = useState(false);
  const [selectedPriceRange, setSelectedPriceRange] = useState(null);
  const listings = useSelector((state) => state.appContext.listings);
  const togglePriceFilter = () => {
    setIsPriceFilterOpen(!isPriceFilterOpen);
  };

  const handlePriceRangeSelect = (range) => {
    setSelectedPriceRange(range);
    setIsPriceFilterOpen(false);
  };

  return (
    <div className="max-w-7xl mx-auto p-4">
      {/* Search Bar */}
      <div className="flex items-center mb-4">
        <input
          type="text"
          placeholder="Ciudad, sector, codigo postal..."
          className="flex-grow p-2 border rounded-l-md"
        />
        <button className="bg-green-500 text-white p-2 rounded-r-md">
          <Search size={20} />
        </button>
      </div>

      {/* Filters */}
      <div className="flex flex-wrap gap-2 mb-4">
        <button className="flex items-center bg-gray-100 px-3 py-1 rounded-full">
          <Settings size={16} className="mr-1" /> All
          <ChevronDown size={16} className="ml-1" />
        </button>
        <button
          className="flex items-center bg-gray-100 px-3 py-1 rounded-full"
          onClick={togglePriceFilter}
        >
          Daily price <ChevronDown size={16} className="ml-1" />
        </button>
        {/* Add more filter buttons here */}
      </div>

      {/* Listings */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {listings.map((listing) => (
          <Link to={"" + listing.id}>
            <div key={listing.id} className="border rounded-lg overflow-hidden">
              <img
                src={listing.mainPicture.url}
                alt="Car"
                className="w-full h-48 object-cover"
              />
              <div className="p-4">
                <div className="flex justify-between items-center mb-2">
                  <h3 className="font-bold">
                    {listing.vehicle.brand} {listing.vehicle.model}{" "}
                    {listing.vehicle.year}
                  </h3>
                  <Heart size={20} />
                </div>
                <div className="flex items-center text-sm text-gray-600 mb-2">
                  <MapPin size={16} className="mr-1" /> Lake Worth
                </div>
                <div className="flex justify-between items-center">
                  <span className="font-bold">
                    ${Math.round(listing.dailyPrice)}/día
                  </span>
                  <span className="text-sm text-gray-600">
                    4.98 ★ (56 trips)
                  </span>
                </div>
              </div>
            </div>
          </Link>
        ))}
      </div>

      {/* Price Filter Overlay */}
      {isPriceFilterOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-4 rounded-lg">
            <h3 className="font-bold mb-2">Select Price Range</h3>
            <button
              className="block w-full text-left p-2 hover:bg-gray-100"
              onClick={() => handlePriceRangeSelect("0-50")}
            >
              $0 - $50
            </button>
            <button
              className="block w-full text-left p-2 hover:bg-gray-100"
              onClick={() => handlePriceRangeSelect("50-100")}
            >
              $50 - $100
            </button>
            <button
              className="block w-full text-left p-2 hover:bg-gray-100"
              onClick={() => handlePriceRangeSelect("100+")}
            >
              $100+
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ListingsSearch;
