import React, { useState } from "react";

import axios from "axios";
import ModalBox from "../ModalBox";
import { useDispatch } from "react-redux";
import { loginSuccess } from "../../stores/stores";
import backendUrl from "../functions/backendUrl";
let baseUrl = backendUrl();

const RegisterModal = ({ show, handleClose, onShowLogin }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [fetching, setFetching] = useState(false);
  const [error, setError] = useState({ status: false, content: "" });
  const dispatch = useDispatch();

  const validateEmail = (email) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const register = async () => {
    setFetching(true);
    setError({ status: false });

    if (!validateEmail(username)) {
      setError({ status: true, content: "Por favor ingrese un correo válido" });
      setFetching(false);
      return;
    }

    if (password.length < 8) {
      setError({
        status: true,
        content: "Su contraseña debe tener 8 o más caracteres",
      });
      setFetching(false);
      return;
    }

    try {
      const res = await axios.post(`${baseUrl}register/`, {
        username,
        password,
        email: username,
      });
      console.log(res);
      if ((res.status = 201)) {
        const loginRes = await axios.post(`${baseUrl}token-auth/`, {
          identifier: username,
          password,
        });
        dispatch(loginSuccess(loginRes.data.user, loginRes.data.token));
        handleClose();
        setUsername("");
        setPassword("");
      } else {
        setError({
          status: true,
          content: "An error occurred during registration.",
        });
        setFetching(false);
      }
    } catch (err) {
      if (
        err.response &&
        err.response.data.username &&
        err.response.data.username[0] ===
          "A user with that username already exists."
      ) {
        // Try logging in if user already exists
        try {
          const loginRes = await axios.post(`${baseUrl}token-auth/`, {
            identifier: username,
            password,
          });
          dispatch(loginSuccess(loginRes.data.user, loginRes.data.token));
          handleClose();
          setUsername("");
          setPassword("");
        } catch (loginErr) {
          setError({ status: true, content: "Usuario ó contraseña invalida" });
          setFetching(false);
        }
      } else {
        setError({
          status: true,
          content: "An error occurred during registration.",
        });
        setFetching(false);
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    register();
  };

  return (
    <ModalBox show={show} handleClose={handleClose} title="Register">
      <form onSubmit={handleSubmit} className="space-y-6">
        <div className="form-group">
          <label
            htmlFor="registerEmail"
            className="block text-sm font-medium text-gray-700"
          >
            Email address
          </label>
          <input
            type="email"
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            id="registerEmail"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label
            htmlFor="registerPassword"
            className="block text-sm font-medium text-gray-700"
          >
            Password
          </label>
          <input
            type="password"
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            id="registerPassword"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        {error.status && <p className="text-red-500">{error.content}</p>}
        <button
          type="submit"
          className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
          disabled={fetching}
        >
          {fetching ? "Registering..." : "Register"}
        </button>
      </form>
      <div className="mt-3 text-sm">
        <p>
          Already have an account?{" "}
          <a
            href="#"
            onClick={() => {
              handleClose();
              onShowLogin();
            }}
            className="font-medium text-indigo-600 hover:text-indigo-500"
          >
            Login
          </a>
        </p>
      </div>
    </ModalBox>
  );
};

export default RegisterModal;
