import React from 'react';

    const FeaturesComponent = ({ formData, handleInputChange, completeStep }) => {
        const featuresList = [
            { id: 'automatic', label: 'Automatic transmission' },
            { id: 'aux', label: 'AUX input' },
            { id: 'blindSpot', label: 'Blind spot warning' },
            { id: 'gps', label: 'GPS' },
            { id: 'keyless', label: 'Keyless entry' },
            { id: 'toll', label: 'Toll pass' },
            { id: 'usb', label: 'USB input' },
            { id: 'carplay', label: 'Apple CarPlay' },
            { id: 'backupCamera', label: 'Backup camera' },
            { id: 'bluetooth', label: 'Bluetooth' },
            { id: 'heatedSeats', label: 'Heated seats' },
            { id: 'sunroof', label: 'Sunroof' },
            { id: 'usbCharger', label: 'USB charger' }
        ];
    
        return (
            <div className={`step expanded`} id="step6">
                <div className="d-flex">
                    <div className="col-4">
                        <label><input type="checkbox" id="automatic" className="mr-2"
                            checked={formData.features.automatic || false}
                            onChange={(e) => handleInputChange('features', 'automatic', e.target.checked)} /> Automatic transmission</label>
                        <label><input type="checkbox" id="aux" className="mr-2"
                            checked={formData.features.aux || false}
                            onChange={(e) => handleInputChange('features', 'aux', e.target.checked)} /> AUX input</label>
                        <label><input type="checkbox" id="blind-spot" className="mr-2"
                            checked={formData.features.blindSpot || false}
                            onChange={(e) => handleInputChange('features', 'blindSpot', e.target.checked)} /> Blind spot warning</label>
                        <label><input type="checkbox" id="gps" className="mr-2"
                            checked={formData.features.gps || false}
                            onChange={(e) => handleInputChange('features', 'gps', e.target.checked)} /> GPS</label>
                    </div>
                    <div className="col-4">
                        <label><input type="checkbox" id="keyless" className="mr-2"
                            checked={formData.features.keyless || false}
                            onChange={(e) => handleInputChange('features', 'keyless', e.target.checked)} /> Keyless entry</label>
                        <label><input type="checkbox" id="toll" className="mr-2"
                            checked={formData.features.toll || false}
                            onChange={(e) => handleInputChange('features', 'toll', e.target.checked)} /> Toll pass</label>
                        <label><input type="checkbox" id="usb" className="mr-2"
                            checked={formData.features.usb || false}
                            onChange={(e) => handleInputChange('features', 'usb', e.target.checked)} /> USB input</label>
                        <label><input type="checkbox" id="carplay" className="mr-2"
                            checked={formData.features.carplay || false}
                            onChange={(e) => handleInputChange('features', 'carplay', e.target.checked)} /> Apple CarPlay</label>
                    </div>
                    <div className="col-4">
                        <label><input type="checkbox" id="backup-camera" className="mr-2"
                            checked={formData.features.backupCamera || false}
                            onChange={(e) => handleInputChange('features', 'backupCamera', e.target.checked)} /> Backup camera</label>
                        <label><input type="checkbox" id="bluetooth" className="mr-2"
                            checked={formData.features.bluetooth || false}
                            onChange={(e) => handleInputChange('features', 'bluetooth', e.target.checked)} /> Bluetooth</label>
                        <label><input type="checkbox" id="heated-seats" className="mr-2"
                            checked={formData.features.heatedSeats || false}
                            onChange={(e) => handleInputChange('features', 'heatedSeats', e.target.checked)} /> Heated seats</label>
                        <label><input type="checkbox" id="sunroof" className="mr-2"
                            checked={formData.features.sunroof || false}
                            onChange={(e) => handleInputChange('features', 'sunroof', e.target.checked)} /> Sunroof</label>
                        <label><input type="checkbox" id="usb-charger" className="mr-2"
                            checked={formData.features.usbCharger || false}
                            onChange={(e) => handleInputChange('features', 'usbCharger', e.target.checked)} /> USB charger</label>
                    </div>
                </div>
                {/* <span className="error-message">This field is required</span>
                <div className="d-flex justify-content-end">
                    <button className="next-btn btn btn-custom-green mt-2" onClick={() => completeStep('step6', 'step7')}>Next</button>
                </div> */}
            </div>
        );
    };
    
    export default FeaturesComponent;