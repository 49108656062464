import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

function BookingList() {
    const auth = useSelector(state => state.auth);

    let bookings = useSelector(state => state.bookings);  // Assuming bookings are stored in redux state
    bookings = useSelector(state => []); 

    return auth.loggedIn ? (
        <div className="container mt-5">
            <h4>My Bookings</h4>

            
            <div className="booking-cards">
                {bookings.map(booking => (
                    <Link key={booking.id} to={`/bookings/${booking.id}`} className="card" style={{ cursor: 'pointer' }}>
                    <div key={booking.id} className="card">
                        <div className="card-body">
                            <h5 className="card-title">{booking.title}</h5>
                            <p className="card-text">{booking.date}</p>
                            <p>Status: {booking.status}</p>
                        </div>
                    </div>
                    
                    </Link>
                    
                ))}
            </div>
        </div>
        
    ) : (
        <div className="container d-flex justify-content-center align-items-center" style={{ height: '80vh' }}>
            <h1>Not Logged In</h1>
        </div>
    );
}

export default BookingList;
