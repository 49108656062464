import { Link } from "react-router-dom";
import ListingCard from "../ListingCard";
import Hero from "../Hero";
import { useSelector } from "react-redux";
import CarListingSection from "./listings/CarListingSection";
import ListingCardLanding from "./components/ListingCardLanding";
import MobileSearch from "./components/landing/MobileSearch";
import DesktopView from "./components/landing/DesktopView";
import PublishSection from "./components/landing/PublishSection";

function Landing() {
  const user = useSelector((state) => state.user);
  const isLogged = user;
  const listings = useSelector((state) => state.appContext.listings);
  return (
    <>
      <DesktopView />
      <column className="onMobile">
        <column
          className="aic jcc"
          style={{
            backgroundImage: 'url("img/carenportada.jpg")',
            height: 620,
            backgroundSize: "cover",
            backgroundPosition: "calc(30% - 100px) 0%",
            padding: 16,
          }}
        >
          <column className="jcc aic" style={{ marginBottom: 6 }}>
            <h3 style={{ color: "white" }}>Alquila El Mejor Carro</h3>
            <p
              style={{ color: "white", width: "80%", textAlign: "center" }}
              className="mt-sm"
            >
              Para tus próximas vacaciones. Al mejor precio del mercado
            </p>
          </column>
          <MobileSearch />
        </column>
      </column>
      <column className="jcc aic my-l">
        {listings.map((listing) => (
          <ListingCardLanding key={listing.id} listing={listing} />
        ))}
      </column>
      <PublishSection active={isLogged} />
    </>
  );
}

export default Landing;
