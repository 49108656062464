import React, { useState, useEffect } from "react";
import axios from "axios";
import PicturesComponent from "./PicturesComponent";
import FeaturesComponent from "./FeaturesComponent";
import CarLocationComponent from "./CarLocationComponent";
import backendUrl from "../../functions/backendUrl";
import "./ListYourCar.css";
import { useSelector } from "react-redux";

const ListYourCar = () => {
  const user = useSelector((state) => state.auth);

  const [expandedStep, setExpandedStep] = useState("step1"); // Start with step1 expanded
  const [images, setImages] = useState([]);
  const [brands, setBrands] = useState([]);
  const [models, setModels] = useState([]);
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    carDetails: { brand: "", model: "", year: "" },
    carLocation: { address: "", position: null },
    pricing: { pricePerDay: "" },
    insurance: { provider: "" },
    features: {},
    extras: {},
  });

  useEffect(() => {
    const url = `${backendUrl()}getAllBrands`;
    axios
      .get(url)
      .then((response) => setBrands(response.data.brands))
      .catch((error) => console.error("Error fetching brands:", error));
  }, []);

  const toggleStep = (stepId) => {
    setExpandedStep((prevStep) => (prevStep === stepId ? null : stepId));
  };

  const handleBrandChange = (e) => {
    const brand = e.target.value;
    setFormData((prevData) => ({
      ...prevData,
      carDetails: { ...prevData.carDetails, brand },
    }));
    const url = `${backendUrl()}getModels/`;
    axios
      .post(url, { brand })
      .then((response) => {
        setModels(response.data); // Assuming response.data is an array of objects
      })
      .catch((error) => console.error("Error fetching models:", error));
  };

  const handleNext = (stepId) => {
    // console.log("hola handle nsxt", stepId)
    if (validateStep(stepId)) {
      clearErrors(stepId); // Clear errors for the current step
      setFormData((prevData) => ({
        ...prevData,
        [`${stepId}Valid`]: true,
      }));
      const nextStep = getNextStep(stepId);
      if (nextStep) {
        setExpandedStep(nextStep);
      }
    }
  };

  const clearErrors = (stepId) => {
    const newErrors = { ...errors };
    switch (stepId) {
      case "step1":
        delete newErrors.brand;
        delete newErrors.model;
        delete newErrors.year;
        break;
      case "step2":
        delete newErrors.address;
        delete newErrors.position;
        break;
      case "step3":
        delete newErrors.images;
        break;
      case "step4":
        delete newErrors.pricePerDay;
        break;
      case "step5":
        delete newErrors.provider;
        break;
      case "step6":
        delete newErrors.features;
        break;
      default:
        break;
    }
    setErrors(newErrors);
  };

  const getNextStep = (currentStep) => {
    const steps = [
      "step1",
      "step2",
      "step3",
      "step4",
      "step5",
      "step6",
      "step7",
      "step8",
    ];
    const currentIndex = steps.indexOf(currentStep);
    return currentIndex < steps.length - 1 ? steps[currentIndex + 1] : null;
  };

  const validateStep = (stepId) => {
    let isValid = true;
    let newErrors = { ...errors };

    switch (stepId) {
      case "step1":
        if (!formData.carDetails.brand) {
          newErrors.brand = "Brand is required";
          isValid = false;
        }
        if (!formData.carDetails.model) {
          newErrors.model = "Model is required";
          isValid = false;
        }
        if (!formData.carDetails.year) {
          newErrors.year = "Year is required";
          isValid = false;
        }
        break;
      case "step2":
        console.log("step2");
        if (!formData.carLocation.address && !formData.carLocation.position) {
          console.log("not valid address", formData.carLocation);
          newErrors.address = "Address is required";
          isValid = false;
        }
        break;
      case "step3":
        if (images.length < 3) {
          newErrors.images = "At least 3 images are required";
          isValid = false;
        }
        break;
      case "step4":
        if (!formData.pricing.pricePerDay) {
          newErrors.pricePerDay = "Price per day is required";
          isValid = false;
        }
        break;
      case "step5":
        if (!formData.insurance.provider) {
          newErrors.provider = "Insurance provider is required";
          isValid = false;
        }
        break;
      case "step6":
        if (Object.keys(formData.features).length === 0) {
          newErrors.features = "At least one feature is required";
          isValid = false;
        }
        break;
      default:
        break;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleFieldChange = (section, field, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [section]: {
        ...prevData[section],
        [field]: value,
      },
    }));
  };

  const handlePicturesError = (message) => {
    setErrors((prevErrors) => ({
      ...prevErrors,
      images: message,
    }));
  };

  const years = [];
  for (let year = 2025; year >= 2012; year--) {
    years.push(year);
  }

  const submitForm = () => {
    alert("Form submitted!");
  };

  const isFormValid =
    formData.carDetails.brand !== "" &&
    formData.carDetails.model !== "" &&
    formData.carDetails.year !== "" &&
    (formData.carLocation.address !== "" || formData.carLocation.position) &&
    formData.pricing.pricePerDay !== "" &&
    formData.insurance.provider !== "" &&
    Object.values(formData.features).some((value) => value !== "") &&
    Object.values(formData.extras).some((value) => value !== "");

  return user.loggedIn ? (
    <div id="listBody">
      <div className="container-fluid">
        <div className="row p-3 pt-4">
          <h4 className="text-center">List Your Car</h4>
        </div>
        <div className="px-4 nopad">
          {/* Step 1 */}
          <div className="tab" onClick={() => toggleStep("step1")}>
            <span className="arrow"></span>
            <span className="label">Car Details</span>
            {formData.step1Valid && <div className="checkmark">&#10003;</div>}
          </div>
          {expandedStep === "step1" && (
            <div className="step expanded">
              <form>
                <div>
                  <label htmlFor="brand">Brand:</label>
                  <select
                    id="brand"
                    value={formData.carDetails.brand}
                    onChange={handleBrandChange}
                    required
                    className={errors.brand ? "error" : ""}
                    style={{ width: "200px" }}
                  >
                    <option value="">Select a brand</option>
                    {brands.sort().map((brand, index) => (
                      <option key={index} value={brand}>
                        {brand}
                      </option>
                    ))}
                  </select>
                  {errors.brand && (
                    <span className="error-message">{errors.brand}</span>
                  )}
                </div>
                <div>
                  <label htmlFor="model">Model:</label>
                  <select
                    id="model"
                    value={formData.carDetails.model}
                    onChange={(e) =>
                      handleFieldChange("carDetails", "model", e.target.value)
                    }
                    required
                    className={errors.model ? "error" : ""}
                    style={{ width: "200px" }}
                  >
                    <option value="">Select a model</option>
                    {[...new Set(models.map((model) => model.model))]
                      .sort((a, b) => a.localeCompare(b))
                      .map((model, index) => (
                        <option key={index} value={model}>
                          {model}
                        </option>
                      ))}
                  </select>
                  {errors.model && (
                    <span className="error-message">{errors.model}</span>
                  )}
                </div>
                <div>
                  <label htmlFor="year">Year:</label>
                  <select
                    id="year"
                    value={formData.carDetails.year}
                    onChange={(e) =>
                      handleFieldChange("carDetails", "year", e.target.value)
                    }
                    required
                    className={errors.year ? "error" : ""}
                    style={{ width: "200px" }}
                  >
                    <option value="">Select Year</option>
                    {years
                      .sort((a, b) => a - b)
                      .reverse()
                      .map((year) => (
                        <option key={year} value={year}>
                          {year}
                        </option>
                      ))}
                  </select>
                  {errors.year && (
                    <span className="error-message">{errors.year}</span>
                  )}
                </div>
                <div
                  className="next-button-container"
                  style={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <button
                    type="button"
                    className="next-btn btn btn-custom-green mt-2"
                    onClick={() => handleNext("step1")}
                  >
                    Next
                  </button>
                </div>
              </form>
            </div>
          )}

          {/* Step 2 */}
          <div className="tab" onClick={() => toggleStep("step2")}>
            <span className="arrow"></span>
            <span className="label">Car Location</span>
            {formData.step2Valid && <div className="checkmark">&#10003;</div>}
          </div>
          {expandedStep === "step2" && (
            <div className="step expanded">
              <CarLocationComponent
                formData={formData}
                setFormData={setFormData}
                expandedStep={expandedStep}
                handleInputChange={handleFieldChange}
                completeStep={handleNext}
              />
              <div className="d-flex justify-content-end">
                <button
                  className="next-btn btn btn-custom-green mt-2"
                  onClick={() => handleNext("step2")}
                >
                  Next
                </button>
              </div>
            </div>
          )}

          {/* Step 3 */}
          <div className="tab" onClick={() => toggleStep("step3")}>
            <span className="arrow"></span>
            <span className="label">Pictures</span>
            {formData.step3Valid && <div className="checkmark">&#10003;</div>}
          </div>
          {expandedStep === "step3" && (
            <div className="step expanded">
              <PicturesComponent
                images={images}
                setImages={setImages}
                onError={handlePicturesError}
              />
              {errors.images && (
                <span className="error-message">{errors.images}</span>
              )}
              <div
                className="next-button-container"
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <button
                  type="button"
                  className="next-btn btn btn-custom-green mt-2"
                  onClick={() => handleNext("step3")}
                >
                  Next
                </button>
              </div>
            </div>
          )}

          {/* Step 4 */}
          <div className="tab" onClick={() => toggleStep("step4")}>
            <span className="arrow"></span>
            <span className="label">Pricing</span>
            {formData.step4Valid && <div className="checkmark">&#10003;</div>}
          </div>
          {expandedStep === "step4" && (
            <div className="step expanded">
              <input
                type="text"
                value={formData.pricing.pricePerDay}
                placeholder="Price per day"
                className={`form-control ${errors.pricePerDay ? "error" : ""}`}
                required
                onInput={(e) =>
                  handleFieldChange("pricing", "pricePerDay", e.target.value)
                }
              />
              {errors.pricePerDay && (
                <span className="error-message">{errors.pricePerDay}</span>
              )}
              <div className="d-flex justify-content-end">
                <button
                  className="next-btn btn btn-custom-green mt-2"
                  onClick={() => handleNext("step4")}
                >
                  Next
                </button>
              </div>
            </div>
          )}

          {/* Step 5 */}
          <div className="tab" onClick={() => toggleStep("step5")}>
            <span className="arrow"></span>
            <span className="label">Insurance and Protection</span>
            {formData.step5Valid && <div className="checkmark">&#10003;</div>}
          </div>
          {expandedStep === "step5" && (
            <div className="step expanded">
              <input
                type="text"
                value={formData.insurance.provider}
                placeholder="Insurance provider"
                className={`form-control ${errors.provider ? "error" : ""}`}
                required
                onInput={(e) =>
                  handleFieldChange("insurance", "provider", e.target.value)
                }
              />
              {errors.provider && (
                <span className="error-message">{errors.provider}</span>
              )}
              <div className="d-flex justify-content-end">
                <button
                  className="next-btn btn btn-custom-green mt-2"
                  onClick={() => handleNext("step5")}
                >
                  Next
                </button>
              </div>
            </div>
          )}

          {/* Step 6 */}
          <div className="tab" onClick={() => toggleStep("step6")}>
            <span className="arrow"></span>
            <span className="label">Features</span>
            {formData.step6Valid && <div className="checkmark">&#10003;</div>}
          </div>
          {expandedStep === "step6" && (
            <div className="step expanded">
              <FeaturesComponent
                formData={formData}
                handleInputChange={handleFieldChange}
                completeStep={handleNext}
              />
              {errors.features && (
                <span className="error-message">{errors.features}</span>
              )}
              <div
                className="next-button-container"
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <button
                  type="button"
                  className="next-btn btn btn-custom-green mt-2"
                  onClick={() => handleNext("step6")}
                >
                  Next
                </button>
              </div>
            </div>
          )}

          {/* Step 7 */}
          <div className="tab" onClick={() => toggleStep("step7")}>
            <span className="arrow"></span>
            <span className="label">Extras</span>
            {formData.step7Valid && <div className="checkmark">&#10003;</div>}
          </div>
          {expandedStep === "step7" && (
            <div className="step expanded">
              <label>
                <input
                  type="checkbox"
                  id="prepaid-refuel"
                  className="mr-2"
                  checked={formData.extras["prepaidRefuel"] || false}
                  onChange={(e) =>
                    handleFieldChange(
                      "extras",
                      "prepaidRefuel",
                      e.target.checked
                    )
                  }
                />{" "}
                Prepaid refuel - $45/trip
              </label>
              <br />
              {errors.extras && (
                <span className="error-message">{errors.extras}</span>
              )}
              <div className="d-flex justify-content-end">
                <button
                  className="next-btn btn btn-custom-green mt-2"
                  onClick={() => handleNext("step7")}
                >
                  Next
                </button>
              </div>
            </div>
          )}

          {/* Step 8 */}
          <div className="tab" onClick={() => toggleStep("step8")}>
            <span className="arrow"></span>
            <span className="label">Review & Confirm</span>
          </div>
          {expandedStep === "step8" && (
            <div className="step expanded">
              <div className="review-section">
                <h4>Car Details</h4>
                {formData.carDetails.brand !== "" && (
                  <p>Brand: {formData.carDetails.brand}</p>
                )}
                {formData.carDetails.model !== "" && (
                  <p>Model: {formData.carDetails.model}</p>
                )}
                {formData.carDetails.year !== "" && (
                  <p>Year: {formData.carDetails.year}</p>
                )}

                <h4>Car Location</h4>
                {formData.carLocation.address !== "" && (
                  <p>Address: {formData.carLocation.address}</p>
                )}

                <h4>Pricing</h4>
                {formData.pricing.pricePerDay !== "" && (
                  <p>Price per Day: {formData.pricing.pricePerDay}</p>
                )}

                <h4>Insurance and Protection</h4>
                {formData.insurance.provider !== "" && (
                  <p>Provider: {formData.insurance.provider}</p>
                )}

                <h4>Features</h4>
                {Object.values(formData.features).some(
                  (value) => value !== ""
                ) && (
                  <ul>
                    {Object.entries(formData.features).map(
                      ([key, value]) =>
                        value !== "" && (
                          <li key={key}>{key.replace(/([A-Z])/g, " $1")}</li>
                        )
                    )}
                  </ul>
                )}

                <h4>Extras</h4>
                {Object.values(formData.extras).some(
                  (value) => value !== ""
                ) && (
                  <ul>
                    {Object.entries(formData.extras).map(
                      ([key, value]) =>
                        value !== "" && (
                          <li key={key}>{key.replace(/([A-Z])/g, " $1")}</li>
                        )
                    )}
                  </ul>
                )}
              </div>
              <div className="d-flex justify-content-end">
                <button
                  disabled={!isFormValid}
                  className="next-btn btn btn-custom-green mt-2"
                  onClick={submitForm}
                >
                  Confirm
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  ) : (
    <div className="container-fluid">
      <div
        className="row p-3 pt-4 justify-content-center"
        style={{ margin: "6em" }}
      >
        <h4 className="text-center">Please log in to add a listing</h4>
      </div>
    </div>
  );
};

export default ListYourCar;
