import { Link } from "react-router-dom";

function MobileSearch() {
  return (
    <roundedcard
      className="shadow onMobile"
      style={{
        margin: 16,
        maxWidth: 500,
        backgroundColor: "white",
        marginBottom: 100,
      }}
    >
      <h6>Where</h6>
      <input
        type="text"
        name
        id
        style={{ marginTop: 6 }}
        className="my-xs"
        placeholder="City, airport, address or hotel"
      />
      <h6>Desde</h6>
      <input type="date" name id className="my-xs fw mobileInput" />
      <h6>Hasta</h6>
      <input type="date" name id className="my-xs fw mobileInput" />
      <Link to="/listings">
        <largebutton className="my-xs greenButton">Buscar carros</largebutton>
      </Link>
    </roundedcard>
  );
}

export default MobileSearch;
