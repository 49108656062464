import React from "react";

const ModalBox = ({ show, handleClose, title, children }) => {
  if (!show) {
    return null;
  }

  return (
    <div className="overlay">
      <div className="modal-box">
        <div className="modal-content">
          <row className=" jcb">
            <h5 className="modal-title">{title}</h5>
            <button
              type="button"
              className="close"
              onClick={handleClose}
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </row>
          <div className="modal-body mt-m">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default ModalBox;
