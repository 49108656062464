import React from "react";
import { Search, Settings, PlusCircle } from "lucide-react";

const Dashboard = () => {
  return (
    <div className="max-w-4xl mx-auto p-4 bg-white">
      <div className="text-center mb-10">
        <h1 className="text-3xl font-bold">Welcome, Jerson!</h1>
        <button className="mt-3 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600">
          Complete your listing
        </button>
      </div>

      <div className="flex justify-between items-center mb-6">
        <div className="flex space-x-4">
          <Search className="w-6 h-6 text-gray-700" />
          <Settings className="w-6 h-6 text-gray-700" />
        </div>
        <PlusCircle className="w-6 h-6 text-blue-500 hover:text-blue-600 cursor-pointer" />
      </div>

      <div className="bg-gray-100 p-4 rounded-lg mb-4">
        <div className="flex justify-between items-center">
          <div className="text-lg font-semibold">Your listings</div>
        </div>
        <div className="bg-white p-4 rounded">
          <div className="bg-gray-200 h-32 w-full mb-2 rounded"></div>
          <p className="font-semibold">Honda Civic</p>
          <p className="text-sm text-gray-600">
            Santo Domingo, Distrito Nacional
          </p>
          <span className="inline-block bg-gray-200 text-xs px-2 py-1 rounded mt-2">
            In progress
          </span>
        </div>
      </div>

      <div className="bg-gray-100 p-4 rounded-lg">
        <h3 className="font-semibold mb-3">Resources and tips</h3>
        <p>Earnings dashboard adds interactive charts and reporting hub</p>
      </div>
    </div>
  );
};

export default Dashboard;
