import { Link } from "react-router-dom";

function PublishSection(props) {
  return (
    <>
      {props.active ? null : (
        <row className="BlackBox">
          <div
            className="Frame114"
            style={{
              width: 370.5,
              height: 73,
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "center",
              gap: 16,
              display: "inline-flex",
            }}
          >
            <div
              className="HazQueTuCarroTrabajeParaTi"
              style={{
                textAlign: "right",
                color: "white",
                fontSize: 21,
                fontFamily: "Inter",
                fontWeight: "600",
                wordWrap: "break-word",
              }}
            >
              Haz que tu carro trabaje para ti
            </div>
            <div
              className="DescubreLaFormaMSEleganteDeGenerarIngresosExtra"
              style={{
                alignSelf: "stretch",
                textAlign: "center",
                color: "white",
                fontSize: 16,
                fontFamily: "IBM Plex Sans",
                fontWeight: "400",
                wordWrap: "break-word",
              }}
            >
              Descubre la forma más elegante de Generar Ingresos Extra
            </div>
          </div>
          <Link to="list">
            <button class="greenButton text-white py-2 px-6 my-5  rounded-lg">
              Publica tu Carro
            </button>
          </Link>
        </row>
      )}
    </>
  );
}

export default PublishSection;
