import React from 'react';
import styled from 'styled-components';

const Button = styled.button`
  width: 298px;
  height: 36px;
  border: 1px solid black;
  border-radius: 28px;
  background-color: white;
  display: flex;
  align-items: center;
  padding: 0 20px;
  cursor: pointer;
`;

const ButtonText = styled.span`
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 16px;
  color: black;
  flex-grow: 1;
`;

const VectorIcon = styled.svg`
  width: 8px;
  height: 14px;
  fill: black;
`;

const LinkButton = () => {
  return (
    <Button onClick={() => window.open('https://www.facebook.com', '_blank')}>
      <ButtonText>Link to your Facebook account</ButtonText>
      <VectorIcon viewBox="0 0 8 14">
        <path d="M0.214986 1.18038C0.146313 1.11359 0.0926256 1.03502 0.0569898 0.949163C0.021354 0.863304 0.00446727 0.771834 0.00729461 0.679976C0.010122 0.588119 0.0326077 0.497672 0.0734678 0.413802C0.114328 0.329931 0.172762 0.254278 0.245434 0.191162C0.318106 0.128047 0.403594 0.0787043 0.497014 0.0459527C0.590435 0.013201 0.689959 -0.00231868 0.789906 0.000279837C0.889852 0.00287835 0.988264 0.0235441 1.07952 0.0610972C1.17078 0.0986502 1.25309 0.152355 1.32177 0.219146L7.79193 6.51545C7.92555 6.64534 8 6.81732 8 6.99607C8 7.17482 7.92555 7.34679 7.79193 7.47669L1.32177 13.7737C1.25355 13.8419 1.17125 13.8971 1.07966 13.9358C0.988064 13.9746 0.889002 13.9963 0.788224 13.9996C0.687446 14.0029 0.58696 13.9877 0.492605 13.955C0.398249 13.9223 0.311904 13.8727 0.238586 13.8091C0.165267 13.7454 0.106435 13.669 0.0655086 13.5843C0.024582 13.4996 0.00237611 13.4083 0.000180467 13.3156C-0.00201518 13.223 0.015843 13.1308 0.0527184 13.0446C0.0895938 12.9583 0.144751 12.8797 0.214986 12.8132L6.1919 6.99607L0.214986 1.18038Z" />
      </VectorIcon>
    </Button>
  );
};

export default LinkButton;
