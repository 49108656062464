import React from "react";
import styled from "styled-components";

const CarCard = ({ image, price, title, rating }) => {
  return (
    <CardWrapper>
      <ImageContainer>
        <CarImage src={image} alt={`${title} car`} loading="lazy" />
        <HotLabel>HOT</HotLabel>
        <PriceTag>{price}</PriceTag>
      </ImageContainer>
      <CardDetails>
        <CarTitle>{title}</CarTitle>
        <RatingContainer>
          <RatingValue>{rating}%</RatingValue>
          <RatingIcon
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/9a7b85cbb8cf9be02b70ba0fedeac227492aa87ff5d12032b6f48063ebf37620?apiKey=5cb2fb1eca5947a5aceaec37f3e2113b&&apiKey=5cb2fb1eca5947a5aceaec37f3e2113b"
            alt=""
          />
        </RatingContainer>
      </CardDetails>
    </CardWrapper>
  );
};

const CardWrapper = styled.article`
  display: flex;
  flex-direction: column;
`;

const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  border-radius: 20px;
  overflow: hidden;
  position: relative;
  aspect-ratio: 1.27;
  width: 100%;
  padding-top: 13px;
  color: #fff;
`;

const CarImage = styled.img`
  position: absolute;
  inset: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
`;

const HotLabel = styled.span`
  position: relative;
  border-radius: 8px;
  background-color: var(--Schemes-Shadow, #000);
  align-self: flex-start;
  margin-left: 20px;
  padding: 5px 9px;
  font: 500 12px Roboto, sans-serif;

  @media (max-width: 991px) {
    margin-left: 10px;
  }
`;

const PriceTag = styled.span`
  position: relative;
  border-radius: 0 0 20px 20px;
  background-color: rgba(0, 0, 0, 0.8);
  margin-top: 97px;
  letter-spacing: 0.99px;
  padding: 8px 52px;
  font: 700 22px Roboto, sans-serif;

  @media (max-width: 991px) {
    margin-top: 40px;
    padding: 0 20px;
  }
`;

const CardDetails = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 8px;
  gap: 9px;
`;

const CarTitle = styled.h2`
  color: #2a2a2a;
  font: 700 20px Cambay, sans-serif;
  margin: 0;
`;

const RatingContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 0 2px;
`;

const RatingValue = styled.span`
  color: #00cd15;
  font: 700 18px Roboto, sans-serif;
`;

const RatingIcon = styled.img`
  width: 19px;
  height: 20px;
  object-fit: contain;
`;

export default CarCard;
