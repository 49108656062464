import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

function BookingDetailPage({ match }) {
    const { bookingId } = useParams();  // Use useParams to get the route parameters
    const booking = { id: "1", title: "Car Rental - Toyota Camry", date: "2024-08-01", description: "Car rental booking for Toyota Camry.", status: "Confirmed" };
    const [activeTab, setActiveTab] = useState('details');

    if (!booking) {
        return <div>Booking not found</div>;
    }

    return (
        <div className="container mt-5">
            <div className="tabs">
                <button onClick={() => setActiveTab('details')}>Details</button>
                <button onClick={() => setActiveTab('chat')}>Chat with Owner</button>
            </div>
            <div className="tab-content">
                {activeTab === 'details' && (
                    <div>
                        <h4>{booking.title}</h4>
                        <p>Date: {booking.date}</p>
                        <p>Description: {booking.description}</p>
                    </div>
                )}
                {activeTab === 'chat' && (
                    <div>
                        <p>Chat component goes here</p>
                        {/* Implement chat functionality or embed a chat component */}
                    </div>
                )}
            </div>
        </div>
    );
}

export default BookingDetailPage;
