import React, { useState } from "react";

import axios from "axios";
import ModalBox from "../ModalBox";
import { useDispatch } from "react-redux";
import { loginSuccess } from "../../stores/stores";
import backendUrl from "../functions/backendUrl";

let baseUrl = backendUrl();
const LoginModal = ({ show, handleClose, onShowRegister }) => {
  const dispatch = useDispatch();
  const [identifier, setIdentifier] = useState("");
  const [password, setPassword] = useState("");
  const [fetching, setFetching] = useState(false);
  const [error, setError] = useState({ status: false, content: "" });

  const login = () => {
    setFetching(true);
    setError({ status: false });
    axios
      .post(`${baseUrl}token-auth/`, { identifier, password })
      .then((res) => {
        console.log(res.data, "res.data");
        setFetching(false);
        if (res.data.token) {
          // Save token and user ID in localStorage
          window.localStorage.setItem("token", res.data.token);
          window.localStorage.setItem("userId", res.data.user.id);
          dispatch(loginSuccess(res.data.user, res.data.token));
          handleClose();
        } else {
          setError({
            status: true,
            content: "Invalid email/username or password",
          });
        }
      })
      .catch((error) => {
        setError({
          status: true,
          content: "Invalid email/username or password",
        });
        setFetching(false);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    login();
  };

  return (
    <ModalBox show={show} handleClose={handleClose} title="Login">
      <form onSubmit={handleSubmit} className="space-y-6">
        <div className="form-group">
          <label
            htmlFor="loginIdentifier"
            className="block text-sm font-medium text-gray-700"
          >
            Email/Username
          </label>
          <input
            type="text"
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            id="loginIdentifier"
            value={identifier}
            onChange={(e) => setIdentifier(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label
            htmlFor="loginPassword"
            className="block text-sm font-medium text-gray-700"
          >
            Password
          </label>
          <input
            type="password"
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            id="loginPassword"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        {error.status && <p className="text-red-500">{error.content}</p>}
        <button
          type="submit"
          className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
          disabled={fetching}
        >
          {fetching ? "Logging in..." : "Login"}
        </button>
      </form>
      <div className="mt-4 text-sm">
        <a
          href="#"
          onClick={() => {
            handleClose();
            onShowRegister();
          }}
          className="font-medium text-indigo-600 hover:text-indigo-500"
        >
          Olvidaste tu contraseña?
        </a>
      </div>
      <div className="mt-1 text-sm">
        <p>
          Don't have an account?{" "}
          <a
            href="#"
            onClick={() => {
              handleClose();
              onShowRegister();
            }}
            className="font-medium text-indigo-600 hover:text-indigo-500"
          >
            Register
          </a>
        </p>
      </div>
    </ModalBox>
  );
};

export default LoginModal;
