import React, { useState } from "react";
import { MessageSquare, HelpCircle } from "lucide-react";

const Inbox = () => {
  const [activeTab, setActiveTab] = useState("booking");

  const chats = [
    {
      id: 1,
      name: "John Doe",
      message: "Hello, when can we meet?",
      time: "10:24 AM",
      imageUrl: "https://via.placeholder.com/50",
    },
    {
      id: 2,
      name: "Jane Smith",
      message: "Thank you for the update!",
      time: "Yesterday",
      imageUrl: "https://via.placeholder.com/50",
    },
  ];

  return (
    <div className="max-w-4xl mx-auto p-4 bg-white">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold">Inbox</h1>
        <div className="flex space-x-4">
          <button
            className={`px-3 py-1 ${
              activeTab === "booking"
                ? "bg-blue-500 text-white"
                : "bg-gray-200 text-gray-800"
            } rounded`}
            onClick={() => setActiveTab("booking")}
          >
            <MessageSquare className="inline-block mr-2" /> Booking Chats
          </button>
          <button
            className={`px-3 py-1 ${
              activeTab === "support"
                ? "bg-blue-500 text-white"
                : "bg-gray-200 text-gray-800"
            } rounded`}
            onClick={() => setActiveTab("support")}
          >
            <HelpCircle className="inline-block mr-2" /> Support
          </button>
        </div>
      </div>

      <div className="space-y-4">
        {chats.map((chat) => (
          <div
            key={chat.id}
            className="flex items-center p-4 bg-gray-100 rounded-lg"
          >
            <img
              className="w-10 h-10 rounded-full mr-3"
              src={chat.imageUrl}
              alt={chat.name}
            />
            <div className="flex-grow">
              <div className="flex justify-between">
                <h5 className="font-semibold">{chat.name}</h5>
                <span className="text-sm text-gray-500">{chat.time}</span>
              </div>
              <p className="text-gray-700">{chat.message}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Inbox;
