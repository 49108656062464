import { Link } from "react-router-dom";

function TripCard() {
  return (
    <Link to="0">
      <div className="bg-white pc rounded-lg shadow-md overflow-hidden">
        <div className="p-4 flex items-center">
          <div className="w-16 h-16 bg-gray-200 rounded-md mr-4"></div>
          <div>
            <div className="font-semibold">Honda Civic 2019</div>
            <p className="text-gray-600">Hosted by Salomon</p>
            <p className="text-gray-500 text-sm">Oct 28-29, 2023</p>
          </div>
        </div>
      </div>
    </Link>
  );
}

export default TripCard;
