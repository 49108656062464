import React, { useState } from 'react';
import styled from 'styled-components';

const Row = styled.div`
  display: flex;
  align-items: center;
  height: 21.58px;
  border: 1px solid #ccc;
  cursor: pointer;
  user-select: none;
`;

const Text = styled.span`
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  margin-left: 22.58px;
`;

const RadioButton = styled.input.attrs({ type: 'radio' })`
  appearance: none;
  width: 18px;
  height: 18px;
  border: 2px solid rgba(0, 205, 20, 1);
  border-radius: 50%;
  position: relative;
  outline: none;
  cursor: pointer;

  &:checked::before {
    content: '';
    width: 6px;
    height: 10px;
    border: solid rgba(0, 205, 20, 1);
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    position: absolute;
    top: 2px;
    left: 5px;
  }
`;

const RowWithRadio = styled(Row)`
  height: 18px;
`;

const RadioText = styled(Text)`
  margin-left: 24px;
`;

const MyComponent = () => {
  const [selected, setSelected] = useState(null);

  const handleChange = (option) => {
    setSelected(option);
  };

  return (
    <div>
      <Row onClick={() => handleChange('yes')}>
        <RadioButton
          checked={selected === 'yes'}
          onChange={() => handleChange('yes')}
        />
        <Text>Yes, I am an expert</Text>
      </Row>
      <RowWithRadio onClick={() => handleChange('no')}>
        <RadioButton
          checked={selected === 'no'}
          onChange={() => handleChange('no')}
        />
        <RadioText>No.</RadioText>
      </RowWithRadio>
    </div>
  );
};

export default MyComponent;