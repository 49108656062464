import React, { useEffect, useRef } from 'react';



const CarLocationComponent = ({ formData, setFormData, expandedStep, handleInputChange, completeStep }) => {
    const mapRef = useRef(null);
    const addressInputRef = useRef(null);
    const mapInstance = useRef(null);
    const autocompleteInstance = useRef(null);
    const marker = useRef(null);

    useEffect(() => {
        if (expandedStep === 'step2' && mapRef.current && window.google && window.google.maps && !mapInstance.current) {
            initMap();
        }
    }, [expandedStep]);

    const initMap = () => {
        mapInstance.current = new window.google.maps.Map(mapRef.current, {
            center: { lat: -34.397, lng: 150.644 },
            zoom: 14
        });

        autocompleteInstance.current = new window.google.maps.places.Autocomplete(addressInputRef.current);
        autocompleteInstance.current.bindTo('bounds', mapInstance.current);

        autocompleteInstance.current.addListener('place_changed', () => {
            const place = autocompleteInstance.current.getPlace();
            if (!place.geometry) {
                console.log("No geometry found for the place.");
                return;
            }

            const location = place.geometry.location;
            updateMarker({ lat: location.lat(), lng: location.lng() }, place.formatted_address);
        });

        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                const pos = {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude
                };
                updateMarker(pos);
                mapInstance.current.setCenter(pos);
            }, () => {
                handleLocationError(true);
            });
        } else {
            handleLocationError(false);
        }
    };

    const updateMarker = (location, address = '') => {
        if (marker.current) {
            marker.current.setMap(null);
        }
        const newMarker = new window.google.maps.Marker({
            position: location,
            map: mapInstance.current
        });

        marker.current = newMarker;
        setFormData(prevData => ({
            ...prevData,
            carLocation: {
                address,
                position: location
            }
        }));

        mapRef.current.style.display = 'block';
        addressInputRef.current.style.display = 'none';
    };

    const handleLocationError = (browserHasGeolocation) => {
        console.log(browserHasGeolocation ? "Geolocation service failed." : "Your browser doesn't support geolocation.");
        mapRef.current.style.display = 'none';
        addressInputRef.current.style.display = 'block';
    };

    return (
        <div>
            <div id="map" ref={mapRef} style={{ height: '400px', width: '100%' }}></div>
            <input type="text" id="address-input" ref={addressInputRef} placeholder="Enter your address manually" className="form-control mt-2"
                onInput={(e) => handleInputChange('carLocation', 'address', e.target.value)} />
            <div className="map-info">This is your approximate location</div>
            <span className="error-message">This field is required</span>

        </div>
    );
};

export default CarLocationComponent;
