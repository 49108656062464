// import { RealDashboard } from "./components/pages/RealDashboard";
import React, { useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Provider } from "react-redux";
import NavBar from "./components/NavBar";
import stores from "./stores/stores";
import Landing from "./components/pages/Landing";
import Listing from "./components/pages/Listing"; // example of another page component
import RegisterModal from "./components/userAuth/RegisterModal";
import LoginModal from "./components/userAuth/LoginModal";
// import { ListYourCar } from './components/pages/ListYourCar';
import ListCar from "./components/pages/listcar/ListCar";
import TestingView from "./components/pages/TestingView";
import Account from "./components/pages/Account";
import Book from "./components/pages/book/Book";
import MyTrips from "./components/pages/dashboard/MyTrips";
import BookingDetailPage from "./components/pages/book/BookingDetailsPage";
import BookingList from "./components/pages/book/BookingList";
import Profile from "./components/pages/account/Profile";
import TripDetail from "./components/pages/dashboard/TripDetail";
import Dashboard from "./components/pages/mylistings/Dashboard";
import Inbox from "./components/pages/dashboard/Inbox";
import ListingsSearch from "./components/pages/listings/ListingsSearch";
function App() {
  const [showRegister, setShowRegister] = useState(false);
  const [showLogin, setShowLogin] = useState(false);

  return (
    <Provider store={stores}>
      <Router>
        <NavBar
          onShowRegister={() => {
            setShowRegister(true);
          }}
          onShowLogin={() => setShowLogin(true)}
        />

        <RegisterModal
          show={showRegister}
          handleClose={() => setShowRegister(false)}
          onShowLogin={() => setShowLogin(true)}
        />
        <LoginModal
          show={showLogin}
          handleClose={() => setShowLogin(false)}
          onShowRegister={() => setShowRegister(true)}
        />
        <Routes>
          <Route path="/" element={<Landing />} />

          <Route path="account" element={<Account />} />
          <Route path="/listings/:id" element={<Listing />} />
          <Route path="/list" element={<ListCar />} />
          <Route path="/realdashboard" element={<TestingView />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/listings/:id/book" element={<Book />} />
          <Route path="/trips" element={<MyTrips />} />
          <Route path="/trips/:id" element={<TripDetail />} />
          <Route path="/bookings/:bookingId" element={<BookingDetailPage />} />
          <Route path="/bookings" element={<BookingList />} />
          <Route path="/mylistings" element={<Dashboard />} />
          <Route path="/inbox" element={<Inbox />} />
          <Route path="/testingview" element={<TestingView />} />
          <Route path="/listings" element={<ListingsSearch />} />
        </Routes>
      </Router>
    </Provider>
  );
}

export default App;
