import { createStore, combineReducers } from "redux";
import listingReducer from "./reducers/listings";
// Actions
const LOGIN_SUCCESS = "LOGIN_SUCCESS";
const LOGOUT = "LOGOUT";

export const pushListings = (listings) => ({
  type: "PUSH_LISTINGS",
  payload: { listings },
});
export const pushListing = (listing) => ({
  type: "PUSH_LISTING",
  payload: { listing },
});
export const loginSuccess = (user, token) => ({
  type: LOGIN_SUCCESS,
  payload: { user, token },
});

export const logout = () => ({
  type: LOGOUT,
});

// Reducer
const initialState = {
  user: null,
  token: null,
  loggedIn: false,
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        user: action.payload.user,
        token: action.payload.token,
        loggedIn: true,
      };
    case LOGOUT:
      return {
        ...state,
        user: null,
        token: null,
        loggedIn: false,
      };
    default:
      return state;
  }
};

// Combine reducers if you have multiple reducers
const rootReducer = combineReducers({
  auth: userReducer,
  appContext: listingReducer,
  // Add other reducers here if needed
});

// Create the store
const stores = createStore(rootReducer);

export default stores;
