import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  Star,
  MapPin,
  ChevronRight,
  User,
  Calendar,
  Clock,
  Info,
  Share2,
  Heart,
} from "lucide-react";
import TripDetails from "./listings/TripDetails";

function Listing() {
  const params = useParams();
  const id = params.id;
  const listings = useSelector((state) => state.appContext.listings);

  const listing = listings.find((listing) => listing.id === Number(id));

  let ownerName, carName, imageUrl, ownerProfilePicture;
  if (listing) {
    const owner = listing.owner;
    ownerName = owner.first_name + " " + owner.last_name;
    carName =
      listing.vehicle.brand +
      " " +
      listing.vehicle.model +
      " " +
      listing.vehicle.year;
    imageUrl = listing.mainPicture.url;
    ownerProfilePicture = owner.profilePicture.url;
  }

  // Set up useState for carDetails
  const [carDetails, setCarDetails] = useState({
    name: carName,
    image: imageUrl,
    rating: 4.96,
    trips: 65,
    price: 50,
    specs: [
      { icon: "speedometer", text: "30 MPG" },
      { icon: "fuel", text: "Gas (Regular)" },
      { icon: "door", text: "4 doors" },
      { icon: "seat", text: "5 seats" },
    ],
    host: {
      name: ownerName,
      image: "/api/placeholder/100/100",
      trips: 147,
      joinedDate: "Ingresó en Nov 2019",
      responseTime: "Typically responds within 1 minute",
    },
    description:
      "I’m excited to offer my 2017 Honda Civic EX for rent. This reliable and fuel-efficient car is perfect for both city driving and long road trips.",
    features: [
      "Automatic transmission",
      "Keyless entry",
      "Android Auto",
      "Backup camera",
      "AUX input",
      "Bluetooth",
      "Heated seats",
      "Toll pass",
      "Blind spot warning",
      "Wireless Charger",
    ],
    trip: {
      startDate: "17 July, 2024",
      startTime: "10:00 AM",
      endDate: "19 July, 2024",
      endTime: "10:00 AM",
      location: "Newburgh, NY 12550",
    },
    distanceIncluded: 600,
    insurance: "Insurance via Travelers",
  });

  // Event handlers for date and time
  const handleStartDateChange = (event) => {
    setCarDetails({
      ...carDetails,
      trip: {
        ...carDetails.trip,
        startDate: event.target.value,
      },
    });
  };

  const handleStartTimeChange = (event) => {
    setCarDetails({
      ...carDetails,
      trip: {
        ...carDetails.trip,
        startTime: event.target.value,
      },
    });
  };

  const handleEndDateChange = (event) => {
    setCarDetails({
      ...carDetails,
      trip: {
        ...carDetails.trip,
        endDate: event.target.value,
      },
    });
  };

  const handleEndTimeChange = (event) => {
    setCarDetails({
      ...carDetails,
      trip: {
        ...carDetails.trip,
        endTime: event.target.value,
      },
    });
  };

  const handleLocationChange = (event) => {
    setCarDetails({
      ...carDetails,
      trip: {
        ...carDetails.trip,
        location: event.target.value,
      },
    });
  };
  return (
    <div className="max-w-6xl mx-auto bg-white shadow-lg rounded-lg overflow-hidden">
      <div className="md:flex flex-col md:flex-row">
        <div className="md:w-2/3 flex flex-col">
          <div className="relative">
            <img
              src={carDetails.image}
              alt={carDetails.name}
              className="w-full object-cover"
              style={{ height: "23vw", minHeight: "300px" }}
            />
            <div
              style={{ top: 12, left: 12 }}
              className="absolute top-4 left-4 bg-white rounded-full px-2 py-1 text-xs"
            >
              1 of 16
            </div>
            <div
              className="absolute top-4 flex bg-green-100 p-2 fw rounded-lg mb-4  justify-between "
              style={{ bottom: -16 }}
            >
              {carDetails.specs.map((spec, index) => (
                <div key={index} className="flex items-center text-xs">
                  <span className="mr-1">
                    {spec.icon === "speedometer"
                      ? "🚗"
                      : spec.icon === "fuel"
                      ? "⛽"
                      : spec.icon === "door"
                      ? "🚪"
                      : "💺"}
                  </span>
                  <span>{spec.text}</span>
                </div>
              ))}
            </div>
            <div
              className="absolute flex space-x-2"
              style={{ top: 12, right: 12 }}
            >
              <button className="bg-white bg-opacity-75 hover:bg-opacity-100 transition-opacity duration-200 rounded-full p-2">
                <Share2 className="w-4 h-4" />
              </button>
              <button className="bg-white bg-opacity-75 hover:bg-opacity-100 transition-opacity duration-200 rounded-full p-2">
                <Heart className="w-4 h-4" />
              </button>
            </div>
          </div>

          <div className="p-4">
            <div className="flex justify-between items-center mb-2">
              <h2 className="text-xl font-bold">{carDetails.name}</h2>
              <div className="flex items-center">
                <Star className="w-4 h-4 text-yellow-400 mr-1" />
                <span className="text-sm">
                  {carDetails.rating} ({carDetails.trips} trips)
                </span>
              </div>
            </div>

            <div className="flex items-center mb-4">
              <img
                src={ownerProfilePicture}
                alt={carDetails.host.name}
                className="w-12 h-12 rounded-full mr-3"
              />
              <div>
                <div className="font-semibold">{carDetails.host.name}</div>
                <p className="text-sm text-gray-600">
                  {carDetails.host.trips} rentas • {carDetails.host.joinedDate}
                </p>
                <p className="text-xs text-gray-500">
                  {carDetails.host.responseTime}
                </p>
              </div>
            </div>

            <div className="mb-4">
              <p className="text-green-600 text-sm flex items-center">
                <Star className="w-4 h-4 mr-1" />
                All-Star Host
              </p>
              <p className="text-green-600 text-sm">
                All-Star Hosts like Fredy are the top-rated and most experienced
                hosts on Wheely.
              </p>
              {/* <a href="#" className="text-green-700 text-sm underline">
                Learn more
              </a> */}
            </div>

            <div className="mb-4">
              <div className="font-semibold mb-2">Description</div>
              <p className="text-sm text-gray-700">{carDetails.description}</p>
              {/* <button className="text-green-600 text-sm mt-2">More</button> */}
            </div>

            <div className="mb-4">
              <div className="font-semibold mb-2">Features</div>
              <div className="grid grid-cols-2 gap-2">
                {carDetails.features.map((feature, index) => (
                  <div key={index} className="flex items-center text-sm">
                    <ChevronRight className="w-4 h-4 mr-1 text-green-500" />
                    <span>{feature}</span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div
          className="md:w-1/3 p-6 mb-12  border-t md:border-l bg-white rounded-lg shadow-md"
          style={{ marginBottom: 60, paddingLeft: 24 }}
        >
          <div className="sticky top-4 space-y-6">
            <div className="flex justify-between items-center">
              <span className="text-3xl font-bold text-green-600">
                ${carDetails.price}
              </span>
              <span className="text-gray-500 text-lg">/ day</span>
            </div>

            <div className="space-y-4">
              <div className="space-y-2">
                <label
                  htmlFor="startDate"
                  className="block text-sm font-medium text-gray-700"
                >
                  Start Date
                </label>
                <input
                  type="date"
                  id="startDate"
                  value={carDetails.trip.startDate}
                  onChange={handleStartDateChange}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 mobileInput"
                />
              </div>
              <div className="space-y-2">
                <label
                  htmlFor="startTime"
                  className="block text-sm font-medium text-gray-700"
                >
                  Start Time
                </label>
                <input
                  type="time"
                  id="startTime"
                  value={carDetails.trip.startTime}
                  onChange={handleStartTimeChange}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 mobileInput"
                />
              </div>
            </div>

            <div className="space-y-4">
              <div className="space-y-2">
                <label
                  htmlFor="endDate"
                  className="block text-sm font-medium text-gray-700"
                >
                  End Date
                </label>
                <input
                  type="date"
                  id="endDate"
                  value={carDetails.trip.endDate}
                  onChange={handleEndDateChange}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 mobileInput"
                />
              </div>
              <div className="space-y-2">
                <label
                  htmlFor="endTime"
                  className="block text-sm font-medium text-gray-700"
                >
                  End Time
                </label>
                <input
                  type="time"
                  id="endTime"
                  value={carDetails.trip.endTime}
                  onChange={handleEndTimeChange}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 mobileInput"
                />
              </div>
            </div>

            <div className="flex items-center">
              <MapPin className="w-5 h-5 mr-2 text-green-500" />
              <input
                type="text"
                value={carDetails.trip.location}
                onChange={handleLocationChange}
                placeholder="Enter location"
                className="flex-grow px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
              />
            </div>

            <Link to="book" className="block">
              <button className="w-full bg-green-500 hover:bg-green-600 text-white py-3 px-4 rounded-lg transition duration-200 font-semibold">
                Continue
              </button>
            </Link>

            <p className="text-sm text-gray-600 flex items-center justify-center">
              <Calendar className="w-4 h-4 mr-2 text-green-500" />
              Free cancellation for 24 hours
            </p>

            <div className="space-y-2">
              <div className="font-semibold text-gray-700">
                Distance included
              </div>
              <p className="text-gray-800 font-medium">
                {carDetails.distanceIncluded} mi
              </p>
              <p className="text-sm text-gray-500">
                $0.35/mi fee for additional miles driven
              </p>
            </div>

            <div className="space-y-2">
              <div className="font-semibold text-gray-700">
                INSURANCE & PROTECTION
              </div>
              <p className="flex items-center text-gray-600">
                <Info className="w-4 h-4 mr-2 text-green-500" />
                {carDetails.insurance}
              </p>
            </div>
          </div>
        </div>
        {/* <div className="md:w-1/3 p-6 mb-12 border-t md:border-l trip-details">
          <div className="sticky top-4">
            <div className="flex justify-between items-center mb-4">
              <span className="text-2xl font-bold text-green-600">
                ${carDetails.price}
              </span>
              <span className="text-gray-400">/ day</span>
            </div>

            <div className="mb-4">
              <div className="flex justify-between items-center mb-2">
                <label htmlFor="startDate" className="font-semibold">
                  Start Date
                </label>
                <input
                  type="date"
                  id="startDate"
                  value={carDetails.trip.startDate}
                  onChange={handleStartDateChange}
                  className="text-gray-800 focus:ring-green-500 focus:border-green-500 rounded"
                />
              </div>
              <div className="flex justify-between items-center mb-2">
                <label htmlFor="startTime" className="font-semibold">
                  Start Time
                </label>
                <input
                  type="time"
                  id="startTime"
                  value={carDetails.trip.startTime}
                  onChange={handleStartTimeChange}
                  className="text-gray-800 focus:ring-green-500 focus:border-green-500 rounded"
                />
              </div>
            </div>

            <div className="mb-4">
              <div className="flex justify-between items-center mb-2">
                <label htmlFor="endDate" className="font-semibold">
                  End Date
                </label>
                <input
                  type="date"
                  id="endDate"
                  value={carDetails.trip.endDate}
                  onChange={handleEndDateChange}
                  className="text-gray-800 focus:ring-green-500 focus:border-green-500 rounded"
                />
              </div>
              <div className="flex justify-between items-center mb-2">
                <label htmlFor="endTime" className="font-semibold">
                  End Time
                </label>
                <input
                  type="time"
                  id="endTime"
                  value={carDetails.trip.endTime}
                  onChange={handleEndTimeChange}
                  className="text-gray-800 focus:ring-green-500 focus:border-green-500 rounded"
                />
              </div>
            </div>

            <div className="flex items-center mb-4">
              <MapPin className="w-4 h-4 mr-2 text-green-500" />
              <input
                type="text"
                value={carDetails.trip.location}
                onChange={handleLocationChange}
                placeholder="Enter location"
                className="text-sm text-gray-800 focus:ring-green-500 focus:border-green-500 rounded flex-grow"
              />
            </div>

            <Link to="book">
              <button className="w-full bg-green-500 hover:bg-green-600 text-white py-2 px-4 rounded-lg mb-4 transition duration-200">
                Continue
              </button>
            </Link>

            <p className="text-sm text-gray-600 flex items-center justify-center mb-4">
              <Calendar className="w-4 h-4 mr-2 text-green-500" />
              Free cancellation for 24 hours
            </p>

            <div className="mb-4">
              <div className="font-semibold mb-2">Distance included</div>
              <p className="text-gray-800">{carDetails.distanceIncluded} mi</p>
              <p className="text-sm text-gray-400">
                $0.35/mi fee for additional miles driven
              </p>
            </div>

            <div className="mb-4">
              <div className="font-semibold mb-2">INSURANCE & PROTECTION</div>
              <p className="flex items-center">
                <Info className="w-4 h-4 mr-2 text-green-500" />
                {carDetails.insurance}
              </p>
            </div>
          </div>
        </div> */}
      </div>

      <div className="md:hidden fixed bottom-0 left-0 right-0 bg-white p-4 border-t">
        <div className="flex justify-between items-center">
          <div>
            <span className="text-2xl font-bold">${carDetails.price}</span>
            <span className="text-gray-500">/ day</span>
          </div>
          <Link to="book">
            <button className="greenButton text-white py-2 px-6 rounded-lg">
              Continue
            </button>
          </Link>
        </div>
      </div>

      <style jsx>{`
        @media (max-width: 767px) {
          .md\\:flex {
            display: block;
          }
          .md\\:w-2\\/3 {
            width: 100%;
          }
          .md\\:w-1\\/3 {
            width: 100%;
            padding-left: 0;
            border-left: none;
            border-top: 1px solid #e5e7eb; /* Optional: Add border to separate the sections */
          }
          .md\\:hidden {
            display: block;
          }
          .md\\:sticky {
            position: static;
            top: auto;
          }
          .trip-details {
            padding: 16px; /* Adjust padding as needed */
          }
        }
      `}</style>
    </div>
  );
}

export default Listing;
