const tripModel = {
  id: null,
  listing: null,
  startDate: null,
  endDate: null,
  total: 0,
  location: null,
  status: "pending",
};

const initialState = {
  listing: null,
  listings: [],
  loading: false,
  error: null,
  search: "",
  trip: null,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case "PUSH_LISTING":
      return { ...state, listing: payload.listing };
    case "PUSH_LISTINGS":
      return { ...state, listings: payload.listings };
    default:
      return state;
  }
};
