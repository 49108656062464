import React from "react";
import { CheckCircle, Star } from "lucide-react";
import { useSelector } from "react-redux";
const Profile = () => {
  const user = useSelector((state) => state.auth.user);
  const rating = 4.5; // Example rating

  return (
    <div className="max-w-2xl mx-auto p-4">
      <div className="relative">
        <img
          src="img/bg.jpg"
          alt="Cover"
          className="w-full h-48 object-cover rounded-t-lg"
        />
        <button
          className="absolute top-4 right-4 bg-green-500 text-white px-4 py-2 rounded-full text-sm font-semibold"
          style={{ top: "1rem", right: "1rem" }}
        >
          Edit profile
        </button>
      </div>

      <div className="bg-white shadow-md rounded-b-lg p-6">
        <div className="flex items-center -mt-16 mb-4">
          <img
            style={{ zIndex: 1 }}
            src={
              user.profilePicture ? user.profilePicture.url : "img/profile.jpeg"
            }
            alt="Profile"
            className="w-24 h-24 rounded-full border-4 border-white"
          />
        </div>

        <h1 className="text-2xl font-bold mb-1">Jerson M.</h1>
        <p className="text-gray-600 mb-2">Joined Nov 2019</p>

        {/* Star Rating Row */}
        <div className="flex items-center mb-4">
          {[1, 2, 3, 4, 5].map((star) => (
            <Star
              key={star}
              size={20}
              className={`${
                star <= Math.floor(rating) ? "text-yellow-400" : "text-gray-300"
              } ${
                star === Math.ceil(rating) && !Number.isInteger(rating)
                  ? "text-yellow-400"
                  : ""
              }`}
              fill={star <= Math.floor(rating) ? "currentColor" : "none"}
            />
          ))}
          <span className="ml-2 text-sm font-semibold">
            {rating.toFixed(1)}/5
          </span>
        </div>

        <div className="mb-6">
          <h2 className="text-sm font-semibold mb-2">VERIFIED INFO</h2>
          <div className="space-y-2">
            <div className="flex items-center justify-between">
              <span>Email address</span>
              <CheckCircle className="text-green-500" size={20} />
            </div>
            <div className="flex items-center justify-between">
              <span>Phone number</span>
              <CheckCircle className="text-green-500" size={20} />
            </div>
            <div className="flex items-center justify-between">
              <span>Facebook</span>
              <button className="text-blue-500">Connect account</button>
            </div>
          </div>
        </div>

        <p className="text-sm text-gray-600 mb-6">
          Build trust with other users on Wheely by verifying your contact
          information
        </p>

        <div>
          <h2 className="text-sm font-semibold mb-4">REVIEWS FROM HOSTS</h2>
          <div className="flex items-center space-x-4">
            <div>
              <p className="font-semibold">No reviews yet</p>
              <p className="text-sm text-gray-600">
                Jerson hasn't received a review on Wheely yet.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
