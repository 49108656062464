import React from "react";
import CreditCardComponent from "./account/CreditCardComponent";
import IconRow from "./account/IconRow";
import LinkButton from "./account/LinkButton";
import RadioInputLine from "./account/RadioInputLine";
import WhiteButton from "./buttons/WhiteButton";
import WhiteField from "./components/WhiteField";
import { User } from "lucide-react";

function Account() {
  return (
    <div className="max-w-4xl mx-auto p-4">
      <h1 className="text-2xl font-bold mb-6">Account</h1>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        <div className="space-y-6">
          <section>
            <h2 className="text-lg font-semibold mb-2">Contact information</h2>
            <div className="space-y-4">
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  Email
                </label>
                <div className="mt-1 relative rounded-md shadow-sm">
                  <input
                    type="email"
                    name="email"
                    id="email"
                    className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pr-10 sm:text-sm border-gray-300 rounded-md"
                    placeholder="Email"
                  />
                  <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                    <svg
                      className="h-5 w-5 text-green-500"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </div>
                </div>
                <p className="mt-1 text-sm text-green-600">Verified</p>
              </div>
              <div>
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-gray-700"
                >
                  Password
                </label>
                <div className="mt-1">
                  <button className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                    Update
                  </button>
                </div>
              </div>
              <div>
                <label
                  htmlFor="phone"
                  className="block text-sm font-medium text-gray-700"
                >
                  Mobile Phone
                </label>
                <div className="mt-1 flex items-center">
                  <span className="text-sm text-gray-500">+1 809-855-2645</span>
                  <svg
                    className="ml-2 h-5 w-5 text-green-500"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clipRule="evenodd"
                    />
                  </svg>
                </div>
                <p className="mt-1 text-sm text-green-600">Verified</p>
              </div>
            </div>
          </section>

          <section>
            <h2 className="text-lg font-semibold mb-2">Mobile notifications</h2>
            <div className="space-y-2">
              <div className="flex items-start">
                <input
                  id="notifications"
                  name="notifications"
                  type="checkbox"
                  className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                />
                <label
                  htmlFor="notifications"
                  className="ml-3 text-sm text-gray-700"
                >
                  Enable text message notifications
                </label>
              </div>
              <div className="flex items-start">
                <input
                  id="email-notifications"
                  name="email-notifications"
                  type="checkbox"
                  className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                />
                <label
                  htmlFor="email-notifications"
                  className="ml-3 text-sm text-gray-700"
                >
                  Email notifications
                </label>
              </div>
              <div className="flex items-start">
                <input
                  id="promotions"
                  name="promotions"
                  type="checkbox"
                  className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                />
                <label
                  htmlFor="promotions"
                  className="ml-3 text-sm text-gray-700"
                >
                  Promotions and announcements
                </label>
              </div>
            </div>
            <button className="mt-4 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500">
              Save changes
            </button>
          </section>

          <section>
            <h2 className="text-lg font-semibold mb-2">Transmission</h2>
            <p className="text-sm text-gray-600 mb-2">
              Can you drive manual transmissions?
            </p>
            <div className="space-y-2">
              <div className="flex items-center">
                <input
                  id="manual-yes"
                  name="manual"
                  type="radio"
                  className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                />
                <label
                  htmlFor="manual-yes"
                  className="ml-3 text-sm text-gray-700"
                >
                  Yes, I am an expert
                </label>
              </div>
              <div className="flex items-center">
                <input
                  id="manual-no"
                  name="manual"
                  type="radio"
                  className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                  checked
                />
                <label
                  htmlFor="manual-no"
                  className="ml-3 text-sm text-gray-700"
                >
                  No
                </label>
              </div>
            </div>
            <button className="mt-4 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500">
              Save changes
            </button>
          </section>

          <section>
            <h2 className="text-lg font-semibold mb-2">Approval status</h2>
            <div className="flex items-center text-sm text-green-600">
              <svg
                className="h-5 w-5 mr-2"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path
                  fillRule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                  clipRule="evenodd"
                />
              </svg>
              You are approved
            </div>
          </section>

          <section>
            <h2 className="text-lg font-semibold mb-2">Payment method</h2>
            <p className="text-sm text-gray-600 mb-2">CREDIT CARD</p>
            <div className="flex items-center">
              <svg
                className="h-8 w-8 text-gray-400 mr-2"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z"
                />
              </svg>
              <span className="text-sm text-gray-700">•••• •••• •••• 5038</span>
            </div>
            <button className="mt-4 inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
              Update
            </button>
          </section>

          <section>
            <h2 className="text-lg font-semibold mb-2">Close account</h2>
            <button className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
              Close my account
            </button>
          </section>
        </div>

        <div className="md:pl-8">
          <div className="bg-white p-4 shadow rounded-lg">
            <div className="flex items-center justify-between mb-4">
              <div className="flex items-center">
                <User className="h-12 w-12 rounded-full bg-gray-200 flex items-center justify-center text-gray-400" />
                <span className="ml-4 text-lg font-medium text-gray-900">
                  Jerson Mendez
                </span>
              </div>
              <button className="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                Disconnect (Google)
              </button>
            </div>
            <button className="w-full inline-flex items-center justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
              Link to your Facebook account
              <svg
                className="ml-2 -mr-1 h-5 w-5 text-gray-400"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Account;
