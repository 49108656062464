import { Link } from "react-router-dom";

function ListingCardLanding(props) {
  const listing = props.listing;

  return (
    <Link to={`/listings/${listing.id}`}>
      <article className="car-card ">
        <div className="car-image-wrapper">
          <img
            loading="lazy"
            src={listing.mainPicture.url}
            className="car-image"
            alt="Honda Civic 2019"
          />
          <span className="hot-label">HOT</span>
          <div className="price-tag">
            US$ {Math.round(listing.dailyPrice)}/día
          </div>
        </div>
        <div className="car-details">
          <h2 className="car-model">
            {listing.vehicle.brand} {listing.vehicle.model}{" "}
            {listing.vehicle.year}
          </h2>
          <div className="rating-wrapper">
            <span className="rating-score">90%</span>
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/47053f0dc89277c25b17c4c17a034d8437c1b6f219d3ddec69fa95e83b657fba?apiKey=5cb2fb1eca5947a5aceaec37f3e2113b&&apiKey=5cb2fb1eca5947a5aceaec37f3e2113b"
              className="rating-icon"
              alt
            />
          </div>
        </div>
      </article>
    </Link>
  );
}

export default ListingCardLanding;
