import React from "react";
import {
  ArrowLeft,
  MessageSquare,
  Car,
  Printer,
  ExternalLink,
  ChevronRight,
  FileText,
} from "lucide-react";

const TripDetail = () => {
  return (
    <div className="max-w-md mx-auto bg-white min-h-screen">
      <header className="p-4 flex items-center border-b">
        <ArrowLeft className="w-6 h-6 mr-4" />
        <h1 className="text-lg font-semibold">
          Rental details for Salomon's car
        </h1>
      </header>

      <div className="relative">
        <div className="bg-gray-200 h-48 w-full"></div>
        <div className="absolute top-4 left-4 flex space-x-2">
          <div className="w-2 h-2 bg-white rounded-full"></div>
          <div className="w-2 h-2 bg-green-500 rounded-full"></div>
        </div>
        <button className="absolute top-1/2 right-4 transform -translate-y-1/2 bg-white rounded-full p-2 shadow-md">
          <ChevronRight className="w-5 h-5" />
        </button>
      </div>

      <div className="p-4 border-b">
        <div className="flex justify-between mb-4">
          <div>
            <h2 className="font-semibold">Check-in</h2>
            <p>Sat, Oct 28</p>
            <p>3:00 PM</p>
          </div>
          <div>
            <h2 className="font-semibold">Check-out</h2>
            <p>Sat, Oct 28</p>
            <p>3:00 PM</p>
          </div>
        </div>
        <div className="flex items-center justify-between py-2 border-t">
          <div className="flex items-center">
            <MessageSquare className="w-5 h-5 mr-2" />
            <span>Message your host</span>
          </div>
          <span className="text-gray-500">Salomon</span>
        </div>
        <div className="flex items-center justify-between py-2 border-t">
          <div className="flex items-center">
            <Car className="w-5 h-5 mr-2" />
            <span>Your car Honda Civic 2019</span>
          </div>
          <span className="text-gray-500">900 free miles included</span>
        </div>
      </div>

      <div className="p-4 space-y-4">
        <section>
          <h2 className="font-semibold mb-2">Reservation details</h2>
          <div className="space-y-2">
            <p>
              <span className="font-medium">Who's coming:</span> 2 guests
            </p>
            <p>
              <span className="font-medium">Confirmation code:</span> HMKABXZXR8
            </p>
            <p>
              <span className="font-medium">Cancellation policy:</span> This
              reservation is non-refundable.
            </p>
            <button className="text-blue-600 flex items-center">
              Read more
              <ChevronRight className="w-4 h-4 ml-1" />
            </button>
          </div>
        </section>

        <section>
          <h2 className="font-semibold mb-2">Rules and instructions</h2>
          <div className="space-y-2">
            <p>
              <span className="font-medium">Car manual:</span> You will have
              access to the backdoor keys to have more privacy and have access
              to the place anytime during your stay.
            </p>
            <button className="text-blue-600 flex items-center">
              Show more
              <ChevronRight className="w-4 h-4 ml-1" />
            </button>
            <h3 className="font-medium">Car rules</h3>
            <ul className="list-disc list-inside">
              <li>3 guests maximum</li>
              <li>No pets</li>
              <li>Quiet hours: 10:00 PM - 6:00 AM</li>
            </ul>
            <button className="text-blue-600 flex items-center">
              Show more
              <ChevronRight className="w-4 h-4 ml-1" />
            </button>
          </div>
        </section>

        <section>
          <h2 className="font-semibold mb-2">Hosted by Salomon</h2>
          <div className="flex items-center">
            <div className="w-10 h-10 bg-gray-200 rounded-full mr-3"></div>
            <button className="text-blue-600 flex items-center">
              Show listing
              <ExternalLink className="w-4 h-4 ml-1" />
            </button>
          </div>
        </section>

        <section>
          <h2 className="font-semibold mb-2">Payment info</h2>
          <div className="flex justify-between items-center">
            <span>Amount paid</span>
            <span className="font-semibold">$69.91</span>
          </div>
          <div className="flex items-center mt-2">
            <FileText className="w-5 h-5 mr-2" />
            <button className="text-blue-600">Get receipt</button>
          </div>
          <button className="text-blue-600 flex items-center mt-2">
            Show more
            <ChevronRight className="w-4 h-4 ml-1" />
          </button>
        </section>

        <button className="flex items-center justify-center w-full py-2 border rounded-md mt-4">
          <Printer className="w-5 h-5 mr-2" />
          Print details
        </button>
      </div>
    </div>
  );
};

export default TripDetail;
