import DesktopSearch from "./DesktopSearch";

function DesktopView() {
  return (
    <column className=" aic onDesktop">
      <row
        className="jcc aic"
        style={{
          backgroundImage: "url(img/carenportada.jpg)",
          backgroundSize: "cover",
          backgroundPosition: "center",
          height: 300,
        }}
      >
        <div>
          <column className="jcc aic" style={{ marginBottom: 24 }}>
            <h3 style={{ color: "white" }}>Alquila El Mejor Carro</h3>
            <p
              style={{ color: "white", width: "80%", textAlign: "center" }}
              className="mt-sm"
            >
              Para tus próximas vacaciones. Al mejor precio del mercado
            </p>
          </column>

          <DesktopSearch />
        </div>
      </row>
    </column>
  );
}

export default DesktopView;
